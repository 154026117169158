import React, { useState } from 'react';
import { Grid, Link } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { LoginLayout } from 'components/Layout';
import { EmailForm, PasswordForm, TextForm } from 'components/Items/Form';
import { LargeSubmitButton } from 'components/Items/Button';
import { FormModal, AlertModal } from 'components/Items/Modal';
import LOGO from 'images/logo.png';


const LoginView = (props) => {
    const { search, setSearch, openPasswordFind, setOpenPasswordFind, searching } = props;
    const navigate = useNavigate();
    const [showPassword, setShowPassword] = useState(false);
    const [openEmailFind, setOpenEmailFind] = useState(false);
    

    const handleChangeEmail = (e) => {
        props.setUser({ ...props.user, Email: e.target.value });
    };
    const handleChangePassword = (e) => {
        props.setUser({ ...props.user, Password: e.target.value });
    };

    const handleCloseFindEmailModal = () => {
        setOpenEmailFind(false);
        setSearch({ Name: '', Email: '', Phone: '' });
        props.setSearchEmail('');
    };

    const handleCloseFindPasswordModal = () => {
        setOpenPasswordFind(false);
        setSearch({ Name: '', Email: '', Phone: '' });
    };

    const handleChangePhone = (e) => {
        let tump = e.target.value.replaceAll('-', '');
        tump = tump.replace(/[\a-zA-Zㄱ-ㅎㅏ-ㅣ가-힣]/g, ''); // eslint-disable-line
        tump = tump.replace(/[ \{\}\[\]\/?.,;:|\)*~`!^\-_+┼<>@\#$%&\'\"\\\(\=]/gi, ''); // eslint-disable-line
        if(tump.length < 12){
            if(tump.length === 9){
                tump = tump.replace(/^(\d{0,2})(\d{0,3})(\d{0,4})$/g, "$1-$2-$3").replace(/\-{1,2}$/g, ''); // eslint-disable-line
            }else if(tump.length === 10){
                tump = tump.replace(/^(\d{0,3})(\d{0,3})(\d{0,4})$/g, "$1-$2-$3").replace(/\-{1,2}$/g, ''); // eslint-disable-line
            }else if(tump.length === 11){
                tump = tump.replace(/^(\d{0,3})(\d{0,4})(\d{0,4})$/g, "$1-$2-$3").replace(/\-{1,2}$/g, ''); // eslint-disable-line
            }
            setSearch({ ...search, Phone: tump });
        }else{
            return false;
        }
    };

    return (
        <>
        <LoginLayout>
            <Grid
                container
                justifyContent={'center'}
                alignItems={'center'}
                style={{
                    minHeight: '100vh',
                    paddingLeft: '1em',
                    paddingRight: '1em',
                    position: 'relative',
                    zIndex: '2'
                }}
            >
                <Grid
                    xs={12} sm={10} md={8} lg={6} xl={4}
                    item
                    style={{
                        backgroundColor: '#FFF',
                        borderRadius: '30px',
                        padding: '60px 4%',
                        position: 'relative'
                    }}
                >
                    <Grid style={{ position: 'absolute', top: '2%', left: '2%' }}>
                        <img alt='logo' src={LOGO} width={120}/>
                    </Grid>
                    <h1 style={{ textAlign: 'center' }}>로그인</h1>
                    <EmailForm
                        label='이메일'
                        error={false}
                        value={props.user.Email}
                        handleChange={handleChangeEmail}
                    />
                    <PasswordForm
                        label='비밀번호'
                        error={false}
                        showPassword={showPassword}
                        handleClick={() => setShowPassword(!showPassword)}
                        value={props.user.Password}
                        handleChange={handleChangePassword}
                        handlePressEnter={props.handleClickLoginBtn}
                    />
                    <LargeSubmitButton
                        label='로그인'
                        loading={props.loading}
                        handleClick={props.handleClickLoginBtn}
                    />
                    <Grid style={{ textAlign: 'center', marginBottom: '16px' }}>
                        <Link onClick={() => navigate('/register')}>회원 가입</Link>
                        &nbsp;&nbsp;&#124;&nbsp;&nbsp;
                        <Link onClick={() => setOpenEmailFind(true)}>이메일 찾기</Link>
                        &nbsp;&nbsp;&#124;&nbsp;&nbsp;
                        <Link onClick={() => setOpenPasswordFind(true)}>비밀번호 찾기</Link>
                    </Grid>
                    <Grid item style={{ textAlign: 'center' }}>
                        서비스 사용이나 제휴에 대하여 언제든 <Link className='link_underline' onClick={() => navigate('/contact')}>문의하세요</Link>.
                    </Grid>
                </Grid>
            </Grid>
        </LoginLayout>
        {props.searchEmail ?
        <AlertModal
            title='이메일 찾기'
            icon='success'
            open={openEmailFind}
            handleClose={handleCloseFindEmailModal}
        >
            이메일: {props.searchEmail}
        </AlertModal>:
        <FormModal
            open={openEmailFind}
            handleClose={handleCloseFindEmailModal}
            title='이메일 찾기'
            submitLabel='찾기'
            handleClickSubmit={props.handleClickSearchEmail}
        >
            <TextForm
                label='이름'
                placeholder='이름을 입력해주세요.'
                error={false}
                value={search.Name}
                handleChange={(e) => setSearch({ ...search, Name: e.target.value })}
            />
            <TextForm
                label='연락처'
                placeholder='연락처 입력해주세요.'
                error={false}
                value={search.Phone}
                handleChange={handleChangePhone}
            />
        </FormModal>}
        <FormModal
            open={openPasswordFind}
            handleClose={handleCloseFindPasswordModal}
            title='비밀번호 찾기'
            submitLabel='찾기'
            handleClickSubmit={() => {props.handleClickSearchPassword();}}
            disabled={searching}
        >
            <TextForm
                label='이름'
                placeholder='이름을 입력해주세요.'
                error={false}
                value={search.Name}
                handleChange={(e) => setSearch({ ...search, Name: e.target.value })}
            />
            <TextForm
                label='연락처'
                placeholder='연락처 입력해주세요.'
                error={false}
                value={search.Phone}
                handleChange={handleChangePhone}
            />
            <EmailForm
                label='이메일'
                error={false}
                value={search.Email}
                handleChange={(e) => setSearch({ ...search, Email: e.target.value })}
            />
        </FormModal>
        </>
    );
};

export default LoginView;