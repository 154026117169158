import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Grid, FormControlLabel, Checkbox } from '@mui/material';

import { DetailContainer } from 'components/Items/Container';
import { DataBox, DataTable } from 'components/Items/Table';
import { SearchForm, TextForm } from 'components/Items/Form';
import { ConfirmModal } from 'components/Items/Modal';
import { DegreeKo, MemberStatus, UniversityStatusKo, LicenseKo, RegionKo } from 'components/Function/ChangeText';
import { PossibleDay, PossibleTime } from 'components/Function/AddDatas';

const MemberDetailView = (props) => {
    const navigate = useNavigate();
    const { memberNo } = useParams();
    const { handleClickDeleteBtn } = props;
    const [openMemberDeleteModal, setOpenMemberDeleteModal] = useState(false);
    const [openTransferModal, setOpenTransferModal] = useState(false);
    const [search, setSearch] = useState('');
    const [changeName, setChangeName] = useState('');
    const [changeConfirm, setChangeConfirm] = useState(false);

    const memberTable = [
        { key: `이름${props.member.ID ? ' / ID' : ''}`, value: `${props.member.Name}${props.member.ID ? ` / ${props.member.ID}` : ''}` },
        { key: '이메일', value: props.member.Email },
        { key: '연락처', value: props.member.Phone },
        { key: '권한', value: MemberStatus(props.member.Status) },
        { key: '학위', value: DegreeKo(props.member.Degree) },
        { key: '수료여부', value: UniversityStatusKo(props.member.UniversityStatus) },
        { key: '학교', value: props.member.University },
        { key: '전공', value: props.member.Major },
    ];
    
    const keys = [{ key: 'No', label: 'No.'},
                        { key: 'NickName', label: '내담자ID'},
                        { key: 'Name_decrypt', label: '이름'},
                        { key: 'AgeValue', label: '만 나이'},
                        { key: 'Birth', label: '생년월일'},
                        { key: 'GenderValue', label: '성별'},
                        { key: 'Phone_decrypt', label: '연락처'}, 
                        { key: 'Email_decrypt', label: '이메일'}];
    
    const handleClickDetail = (data) => {
        navigate(`/manage/client/${data.ClientNo}`);
    };
    const filterDatas = (datas) => {
        return datas;
    };

    return (
        <>
        <DetailContainer
            title='직원 정보'
            icon={[ { type: 'delete', handleClick: () => setOpenMemberDeleteModal(true) },
                    { type: 'update', handleClick: () => navigate(`/manage/member/${memberNo}/update`) }]}
            button={{ label: '상담소 권한 이전', handleClick: () => setOpenTransferModal(true) }}
        >
            <DataBox
                datas={memberTable}
            />
            {props.member.Supervisor_id ?
            <Grid container style={{ marginTop: '8px' }}>
                <Grid item xs={12} md={6}>
                    <Grid
                        container
                        style={{
                            borderRadius: '5px',
                            border: '1px solid #C0C0C0'
                        }}
                        alignItems={'center'}
                    >
                        <Grid
                            item 
                            xs={4} sm={3} 
                            style={{ 
                                backgroundColor: '#D9D9D9', 
                                textAlign: 'center', 
                                fontWeight: 'bold', 
                                borderRadius: '5px 0px 0px 5px', 
                                padding: '4px 16px', 
                                overflow: 'hidden', 
                                whiteSpace: 'nowrap', 
                                textOverflow: 'ellipsis'
                            }}
                        >
                            수퍼바이저
                        </Grid>
                        <Grid 
                            item
                            xs={8} sm={9}
                            style={{
                                paddingLeft: '16px',
                                overflow: 'hidden',
                                whiteSpace: 'nowrap',
                                textOverflow: 'ellipsis'
                            }}
                        >
                            {props.member.Supervisor_id.Name}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>:null}
            <Grid container style={{ marginTop: '8px' }}>
                <Grid item xs={12}>
                    <Grid
                        container
                        style={{
                            borderRadius: '5px',
                            border: '1px solid #C0C0C0'
                        }}
                        alignItems={'stretch'}
                    >
                        <Grid
                            item 
                            xs={4} sm={3} md={1.5} 
                            style={{ 
                                backgroundColor: '#D9D9D9', 
                                textAlign: 'center', 
                                fontWeight: 'bold', 
                                borderRadius: '5px 0px 0px 5px', 
                                padding: '4px 16px', 
                                overflow: 'hidden', 
                                whiteSpace: 'nowrap', 
                                textOverflow: 'ellipsis'
                            }}
                        >
                            희망 상담시간
                        </Grid>
                        <Grid 
                            item
                            xs={8} sm={9} md={10.5}
                            style={{
                                overflow: 'hidden',
                                whiteSpace: 'nowrap',
                                textOverflow: 'ellipsis'
                            }}
                        >
                            <Grid container style={{ textAlign: 'center', borderBottom: '1px solid #C0C0C0', fontSize: '80%' }}>
                                <Grid item xs={12/8} style={{ borderRight: '1px solid #C0C0C0' }}>
                                </Grid>
                                {PossibleDay().map((day, i) => (
                                <Grid item xs={12/8} key={i} style={{ borderRight: i < 6 ? '1px solid #C0C0C0' : '0px' }}>
                                    {day.label.replace('요일', '')}
                                </Grid>
                                ))}
                            </Grid>
                            {PossibleTime().map((time, i) => (
                            <Grid container key={i} style={{ textAlign: 'center', borderBottom: i < 17 ? '1px solid #C0C0C0' : '0px', fontSize: '80%' }}>
                                <Grid item xs={12/8} style={{ borderRight: '1px solid #C0C0C0' }}>
                                    {time.label.split('~')[0].replace(':00 ', '시')}
                                </Grid>
                                {PossibleDay().map((day, j) => (
                                <Grid
                                    item
                                    xs={12/8}
                                    key={j}
                                    style={{ 
                                        backgroundColor: 
                                            props.member.AvailableDay.indexOf(day.value) > -1 && props.member.Available[Number(day.value)-1].indexOf(time.value) > -1 ?
                                            'rgba(161, 216, 160, 0.7)' : 
                                            '', 
                                        borderRight: j < 6 ? '1px solid #C0C0C0' : '0px'
                                    }}
                                />
                                ))}
                            </Grid>
                            ))}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container style={{ marginTop: '8px' }}>
                <Grid item xs={12}>
                    <Grid
                        container
                        style={{
                            borderRadius: '5px',
                            border: '1px solid #C0C0C0'
                        }}
                        alignItems={'center'}
                    >
                        <Grid
                            item 
                            xs={4} sm={3} md={1.5} 
                            style={{ 
                                backgroundColor: '#D9D9D9', 
                                textAlign: 'center', 
                                fontWeight: 'bold', 
                                borderRadius: '5px 0px 0px 5px', 
                                padding: '4px 16px', 
                                overflow: 'hidden', 
                                whiteSpace: 'nowrap', 
                                textOverflow: 'ellipsis'
                            }}
                        >
                            활동지역
                        </Grid>
                        <Grid 
                            item
                            xs={8} sm={9} md={10.5}
                            style={{
                                overflow: 'hidden',
                                whiteSpace: 'nowrap',
                                textOverflow: 'ellipsis',
                                paddingLeft: '16px'
                            }}
                        >
                            {props.member.Region.map((item, i) => (
                            <span key={i}>{i === 0 ? '' : ', '}{RegionKo(item)}</span>
                            ))}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container style={{ marginTop: '8px' }}>
                <Grid item xs={12}>
                    <Grid
                        container
                        style={{
                            borderRadius: '5px',
                            border: '1px solid #C0C0C0'
                        }}
                        alignItems={'stretch'}
                    >
                        <Grid
                            item 
                            xs={4} sm={3} md={1.5} 
                            style={{ 
                                backgroundColor: '#D9D9D9', 
                                textAlign: 'center', 
                                fontWeight: 'bold', 
                                borderRadius: '5px 0px 0px 5px', 
                                padding: '4px 16px', 
                                overflow: 'hidden', 
                                whiteSpace: 'nowrap', 
                                textOverflow: 'ellipsis'
                            }}
                        >
                            자격증
                        </Grid>
                        <Grid 
                            item
                            xs={8} sm={9} md={10.5}
                            style={{
                                overflow: 'hidden',
                                whiteSpace: 'nowrap',
                                textOverflow: 'ellipsis',
                                paddingLeft: '16px'
                            }}
                        >
                            {props.member.License.map((item, i) => (
                            <div key={i}>{LicenseKo(item)}</div>
                            ))}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </DetailContainer>
        {props.member.Status === 1 || props.member.Status === 2 ?
        <DetailContainer
            title='내담자 목록'
        >
            <Grid container>
                <Grid item xs={12} md={6} style={{ marginBottom: '16px'}}>
                    <SearchForm
                        value={search}
                        handleChange={(e) => setSearch(e.target.value)}
                    />
                </Grid>
                <Grid item xs={12}>
                    <DataTable
                        keys={keys}
                        datas={filterDatas(props.clients)}
                        delete={false}
                        update={false}
                        search={search}
                        handleClickDetail={handleClickDetail}
                    />
                </Grid>
            </Grid>
        </DetailContainer>:null}
        <ConfirmModal
            title='직원 삭제'
            icon='error'
            open={openMemberDeleteModal}
            handleClose={() => setOpenMemberDeleteModal(false)}
            submitLabel='삭제'
            handleClickSubmit={() => {setOpenMemberDeleteModal(false);handleClickDeleteBtn();}}
        >
            직원 정보를 삭제 하시겠습니까? 직원 삭제 시 복구가 불가능합니다.
        </ConfirmModal>
        <ConfirmModal
            title='상담소 권한 이전'
            icon='error'
            open={openTransferModal}
            disabled={changeName !== props.member.Name || !changeConfirm}
            handleClose={() => setOpenTransferModal(false)}
            handleClickSubmit={() => { props.handleClickTransfer() }}
            submitLabel='확인'
            loading={props.loading}
        >
            <Grid>
                <span>
                    상담소장 권한을 <b>{props.member.Name}</b>님에게 이전합니다.<br/>
                    새로운 상담소장 권한을 부여받은 계정은 <u>재로그인</u>이 필요합니다.<br/>
                    권한 이전 후 해당 계정은 "상담사" 권한이 부여되며, 자동으로 로그아웃 됩니다.<br/>
                </span>
            </Grid>
            <br/><hr/><br/>
            <Grid>
                <TextForm
                    label=''
                    placeholder='변경될 상담소장의 이름을 작성해 주세요.'
                    required={true}
                    error={false}
                    value={changeName}
                    handleChange={(e) => setChangeName(e.target.value)}
                />
            </Grid>
            <Grid>
                <FormControlLabel onChange={() => setChangeConfirm(!changeConfirm)} control={<Checkbox checked={changeConfirm} />} label='위 내용을 모두 확인하였습니다.' />
            </Grid>
            
            <Grid style={{ textAlign: 'right' }}>
                {changeName !== props.member.Name || !changeConfirm ?
                <small>* 권한 이전할 상담소장의 이름과 이전 시 주의 사항을 확인해 주세요.</small>
                :<small>ㅤ</small>}
            </Grid>
        </ConfirmModal>
        </>
    );
};

export default MemberDetailView;