import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Grid, FormControl, RadioGroup, FormControlLabel, Radio, FormHelperText, FormGroup, Checkbox, Select, MenuItem, ListItemText, Stack, Chip } from '@mui/material';

import { FormContainer } from 'components/Items/Container';
import { LargeSubmitButton, LargeCancelButton, TooltipButton } from 'components/Items/Button';
import { TextForm, EmailForm, PasswordForm, SelectForm } from 'components/Items/Form';
import { UserStatus, LicenseKo, AvailableDay } from 'components/Function/ChangeText';
import { PossibleDay, PossibleTime, License, Region } from 'components/Function/AddDatas';
import { ConfirmModal } from 'components/Items/Modal';

const UserUpdateView = (props) => {
    const user = props.user;
    const setUser = props.setUser;
    const [disabledUpdateBtn, setDisabledUpdateBtn] = useState(true);
    const [deleteConfirm, setDeleteConfirm] = useState(false);
    const [userDeleteModal, setUserDeleteModal] = useState(false);

    const [showPw, setShowPw] = useState([false, false]);
    const regPhone = new RegExp('[0-9]{2,3}-[0-9]{3,4}-[0-9]{4}'); // eslint-disable-line
    const handleChangePhone = (e) => {
        let tump = e.target.value.replaceAll('-', '');
        tump = tump.replace(/[\a-zA-Zㄱ-ㅎㅏ-ㅣ가-힣]/g, ''); // eslint-disable-line
        tump = tump.replace(/[ \{\}\[\]\/?.,;:|\)*~`!^\-_+┼<>@\#$%&\'\"\\\(\=]/gi, ''); // eslint-disable-line
        if(tump.length < 12){
            if(tump.length === 9){
                tump = tump.replace(/^(\d{0,2})(\d{0,3})(\d{0,4})$/g, "$1-$2-$3").replace(/\-{1,2}$/g, ''); // eslint-disable-line
            }else if(tump.length === 10){
                tump = tump.replace(/^(\d{0,3})(\d{0,3})(\d{0,4})$/g, "$1-$2-$3").replace(/\-{1,2}$/g, ''); // eslint-disable-line
            }else if(tump.length === 11){
                tump = tump.replace(/^(\d{0,3})(\d{0,4})(\d{0,4})$/g, "$1-$2-$3").replace(/\-{1,2}$/g, ''); // eslint-disable-line
            }
            setUser({ ...user, Phone: tump });
        }else{
            return false;
        }
    };

    const handleChangeAvailableDay = (e) => {
        let changeUser = { ...user };
        if(changeUser.AvailableDay.indexOf(e.target.value) > -1){
            changeUser.AvailableDay = changeUser.AvailableDay.filter((item) => (item !== e.target.value));
            changeUser.Available[Number(e.target.value)-1] = [];
        }else{
            changeUser.AvailableDay.push(e.target.value);
            changeUser.AvailableDay = changeUser.AvailableDay.sort((a, b) => (a - b));
        }
        setUser(changeUser);
    };
    const handleChangeAllDay = () => {
        let changeUser = { ...user };
        if(changeUser.AvailableDay.length === 7){
            changeUser.AvailableDay = [];
            changeUser.Available = [[], [], [], [], [], [], []];
        }else{
            changeUser.AvailableDay = ['1', '2', '3', '4', '5', '6', '7'];
        }
        setUser(changeUser);
    };
    const handleChangeAvailable = (e, key) => {
        let changeUser = { ...user };
        if(changeUser.Available[Number(key)-1].indexOf(e.target.value) > -1){
            changeUser.Available[Number(key)-1] = changeUser.Available[Number(key)-1].filter((item) => (item !== e.target.value));
        }else{
            changeUser.Available[Number(key)-1].push(e.target.value);
        }
        setUser(changeUser);
    };
    const handleChangeAvailableAll = (key) => {
        let changeUser = { ...user };
        if(changeUser.Available[Number(key)-1].length === 17){
            changeUser.Available[Number(key)-1] = [];
        }else{
            changeUser.Available[Number(key)-1] = ['1','2','3','4','5','6','7','8','9','10','11','12','13','14','15','16','17'];
        }
        setUser(changeUser);
    };

    const handleChangeRegion = (e) => {
        let changeUser = { ...user };
        if(changeUser.Region.indexOf(e.target.value) > -1){
            changeUser.Region = changeUser.Region.filter((item) => (item !== e.target.value));
            changeUser.Available[Number(e.target.value)-1] = [];
        }else{
            changeUser.Region.push(e.target.value);
            changeUser.Region = changeUser.Region.sort((a, b) => (a - b));
        }
        setUser(changeUser);
    };
    const handleChangeAllRegion = (e) => {
        let changeUser = { ...user };
        if(e.target.checked){
            changeUser.Region = ['1','2','3','4','5','6','7','8','9','10','11','12','13','14','15','16','17'];
        }else{
            changeUser.Region = [];
        }
        setUser(changeUser);
    };

    const handleChangeID = (e) => {
        setUser({ ...user, ID: e.target.value });
    };

    const handleChangeDegree = (e) => {
        if(e.target.value){
            setUser({ ...user, Degree: Number(e.target.value) });
        }else{
            setUser({ ...user, Degree: '', UniversityStatus: '' });
        }
    };

    useEffect(() => {
        let tump = false;
        
        if(user.ChangePassword !== user.ConfirmPassword){
            tump = true;
        }
        if(!regPhone.test(user.Phone) || user.Phone === ''){
            tump = true;
        }
        setDisabledUpdateBtn(tump);
    }, [user, setDisabledUpdateBtn, regPhone]);

    return (
        <>
        <FormContainer title='개인정보 수정'>
            <TextForm
                label='이름'
                required={false}
                error={false}
                value={user.Name}
                disabled={true}
            />
            <TextForm
                label='ID'
                required={false}
                error={false}
                placeholder={'ID를 입력해주세요.'}
                value={user.ID}
                handleChange={handleChangeID}
            />
            <EmailForm
                label='이메일'
                required={false}
                error={false}
                value={user.Email}
                disabled={true}
            />
            <PasswordForm
                label='비밀번호 변경'
                required={false}
                error={false}
                value={user.ChangePassword}
                disabled={true}
                handleChange={(e) => setUser({ ...user, ChangePassword: e.target.value })}
                showPassword={showPw[0]}
                handleClick={() => setShowPw([!showPw[0], showPw[1]])}
            />
            <PasswordForm
                label='비밀번호 변경 확인'
                required={false}
                error={user.ChangePassword !== user.ConfirmPassword}
                errorMessage={'비밀번호가 일치하지 않습니다.'}
                value={user.ConfirmPassword}
                disabled={true}
                handleChange={(e) => setUser({ ...user, ConfirmPassword: e.target.value })}
                showPassword={showPw[1]}
                handleClick={() => setShowPw([showPw[0], !showPw[1]])}
            />
            <TextForm
                label='연락처'
                required={true}
                value={user.Phone}
                placeholder='연락처를 입력해주세요.'
                error={!regPhone.test(user.Phone) && user.Phone !== ''}
                errorMessage='연락처 형식이 올바르지 않습니다.'
                handleChange={handleChangePhone}
            />
            <TextForm
                label='권한'
                required={false}
                error={false}
                value={UserStatus(user.Status)}
                disabled={true}
            />
            {user.Status === 2 ?
            <FormControl fullWidth>
                <Grid style={{ marginBottom: '8px' }}>
                    검사(ROM) 정보 공유 여부 
                </Grid>
                <RadioGroup row>
                    <FormControlLabel value={1} control={<Radio checked={user.Open}/>} label={'상담소장(대표관리자)에게 내담자 검사(ROM) 정보를 공유합니다.'} onChange={() => setUser({ ...user, Open: true })}/>
                    <FormControlLabel value={2} control={<Radio checked={!user.Open}/>} label={'상담소장(대표관리자)에게 개별 내담자 검사(ROM) 정보를 공유하지 않습니다. 검사 정보는 기관 전체 평균에만 반영이 됩니다.'} onChange={() => setUser({ ...user, Open: false })}/>
                </RadioGroup>
                <FormHelperText style={{ marginLeft: 0 }}> </FormHelperText>
                {/* <FormHelperText style={{ marginLeft: 0 }}>{user.Open ? '* 대표관리자에게만 내담자 정보를 공개합니다.': '* 대표관리자에게도 내담자 정보를 공개하지 않습니다.'}</FormHelperText> */}
            </FormControl>
            :null}
            <FormHelperText>
                <Grid container justifyContent={'center'} alignItems={'center'}>
                    <Grid item>&nbsp;------------------------&nbsp;</Grid>
                    <Grid item>아래 정보는 선택 입력 사항입니다.</Grid>
                    <Grid item><TooltipButton tooltip={'센터의 필요에 따라 정보 입력 여부를 선택해서 사용하면 됩니다.'}/></Grid>
                    <Grid item>&nbsp;------------------------&nbsp;</Grid>
                </Grid>
            </FormHelperText>
            <FormControl fullWidth>
                <Grid style={{ marginBottom: '8px' }}>
                    상담(근무) 가능 요일
                </Grid>
                <FormGroup row>
                    {PossibleDay().map((item, i) => (
                    <FormControlLabel key={i} value={item.value} onChange={handleChangeAvailableDay} control={<Checkbox checked={user.AvailableDay.indexOf(`${i+1}`) > -1} />} label={item.label} />    
                    ))}
                    <FormControlLabel onChange={handleChangeAllDay} control={<Checkbox checked={user.AvailableDay.length === 7} />} label='전체' />
                </FormGroup>
                <FormHelperText style={{ marginLeft: 0 }}> </FormHelperText>
            </FormControl>
            {user.AvailableDay.map((day, i) => (
            <FormControl fullWidth key={i}>
                <Grid style={{ marginBottom: '8px' }}>
                    {AvailableDay(day)}요일 상담(근무) 가능 시간
                </Grid>
                <FormGroup row>
                    {PossibleTime().map((item, j) => (
                    <FormControlLabel key={j} value={item.value} onChange={(e) => handleChangeAvailable(e, day)} control={<Checkbox checked={user.Available[Number(day)-1].indexOf(item.value) > -1} />} label={item.label} />    
                    ))}
                    <FormControlLabel onChange={() => handleChangeAvailableAll(day)} control={<Checkbox checked={user.Available[Number(day)-1].length === 17}/>} label='전체' />
                </FormGroup>
                <FormHelperText style={{ marginLeft: 0 }}> </FormHelperText>
            </FormControl>
            ))}
            <FormControl fullWidth>
                <Grid style={{ marginBottom: '8px' }}>
                    주요 활동지역
                </Grid>
                <FormGroup row>
                    {Region().map((item, i) => (
                    <FormControlLabel key={i} value={item.value} onChange={handleChangeRegion} control={<Checkbox checked={user.Region.indexOf(`${i+1}`) > -1} />} label={item.label} />    
                    ))}
                    <FormControlLabel onChange={handleChangeAllRegion} control={<Checkbox checked={user.Region.length === 17} />} label='전체' />
                </FormGroup>
                <FormHelperText style={{ marginLeft: 0 }}> </FormHelperText>
            </FormControl>
            <FormControl fullWidth variant='outlined' className='form_default'>                
                <Grid style={{ marginBottom: '8px' }}>
                    자격증
                </Grid>
                <Select
                    multiple
                    value={user.License}
                    onChange={(e) => setUser({ ...user, License: e.target.value })}
                    renderValue={(selected) => `${selected.length}개의 자격증이 선택 됨`}
                >
                {License().map((item, i) => (
                    <MenuItem key={i} value={item.value}>
                        <Checkbox checked={user.License.indexOf(item.value) > -1} />
                        <ListItemText primary={item.label} />
                    </MenuItem>
                ))}
                </Select>
                <Stack direction='row' flexWrap='wrap' style={{ marginTop: 8 }}>
                {user.License.map((item, i) => (
                    <Chip key={i} label={LicenseKo(item)} size='small' variant='outlined' style={{ marginRight: 8, marginBottom: 8 }} />  
                ))}
                </Stack>
                <FormHelperText style={{ marginLeft: 0 }}> </FormHelperText>
            </FormControl>
            <Grid container>
                <Grid mb={1} xs={12}>
                    최종학력
                </Grid>
                <Grid xs={6} pr={1}>
                    <SelectForm
                        label='학위'
                        required={false}
                        error={false}
                        cancel={true}
                        value={Number(user.Degree)}
                        datas={[{ value: '1', label: '학사' }, { value: '2', label: '석사' }, { value: '3', label: '박사' }]}
                        handleChange={handleChangeDegree}
                    />
                </Grid>
                <Grid xs={6} pl={1}>
                    <SelectForm
                        label='수료 여부'
                        required={false}
                        error={false}
                        cancel={true}
                        value={Number(user.UniversityStatus)}
                        datas={[{ value: '1', label: '재학' }, { value: '2', label: '수료' }, { value: '3', label: '졸업' }]}
                        handleChange={(e) => setUser({ ...user, UniversityStatus: Number(e.target.value) })}
                        disabled={!user.Degree}
                    />
                </Grid>
                <Grid xs={6} pr={1}>
                    <TextForm
                        label='학교명'
                        required={false}
                        error={false}
                        value={user.University}
                        disabled={!user.Degree}
                        handleChange={(e) => setUser({ ...user, University: e.target.value })}
                    />
                </Grid>
                <Grid xs={6} pl={1}>
                    <TextForm
                        label='전공'
                        required={false}
                        error={false}
                        value={user.Major}
                        disabled={!user.Degree}
                        handleChange={(e) => setUser({ ...user, Major: e.target.value })}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={1} justifyContent={'space-between'}>
                <Grid item xs={6} md={4}>
                    <LargeCancelButton label='회원 탈퇴' handleClick={() => setUserDeleteModal(true)} disabled={props.loading || props.updating} />
                </Grid>
                <Grid item xs={6} md={4}>
                    <LargeSubmitButton label='수정' handleClick={props.handleClickUpdateBtn} disabled={disabledUpdateBtn || props.loading} loading={props.updating}/>
                </Grid>
            </Grid>
        </FormContainer>
        <ConfirmModal
            title='회원 탈퇴'
            icon='error'
            open={userDeleteModal}
            disabled={!deleteConfirm}
            handleClose={() => setUserDeleteModal(false)}
            handleClickSubmit={() => { props.handleClickUserDeleteBtn() }}
            submitLabel='확인'
            loading={props.loading}
        >
            <Grid>
                {props.status === '1' ?
                <span>
                    회원 탈퇴 시 상담소 내 모든 직원 계정이 사용 불가능합니다.<br/><br/>
                    또 한, <b>모든 데이터는 복구가 불가능</b>합니다.<br/><br/>
                    <u>회원 탈퇴 전 필요한 데이터를 백업 후 탈퇴해 주세요.</u>
                </span>:
                <span>
                    회원 탈퇴 시 <b>모든 데이터는 복구가 불가능</b>합니다.<br/><br/>
                    <u>회원 탈퇴 전 필요한 데이터를 백업 후 탈퇴해 주세요.</u>
                </span>}
            </Grid>
            <br/><hr/><br/>
            <Grid>
                <FormControlLabel onChange={() => setDeleteConfirm(!deleteConfirm)} control={<Checkbox checked={deleteConfirm} />} label='위 내용을 모두 확인하였습니다.' />
            </Grid>
        </ConfirmModal>
        </>
    );
};

export default UserUpdateView;