import React, { useState, useCallback, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { useSnackbar } from 'notistack';
import axios from 'axios';

import { DashboardLayout } from 'components/Layout';
import { UserUpdateView } from 'components/User';
import { useNavigate } from 'react-router-dom';

const UserUpdateContainer = (props) => {
    const pages = [
        { name: '개인정보수정', link: '' }
    ];
    const navigate = useNavigate();
    const [cookies, , removeCookies] = useCookies(['LoginKey', 'Status']);
    const { enqueueSnackbar } = useSnackbar();
    const [user, setUser] = useState({
        AvailableDay: [], // 상담가능 요일
        Available: [[], [], [], [], [], [], []], // 상담 가능 요알, 시간
        Region: [], // 활동 지역
        License: [], // 자격증
        Degree: '',
        UniversityStatus: '',
        University: '',
        Major: '',
        ID: ''
    });
    const [updating, setUpdating] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleClickUpdateBtn = async() => {
        setUpdating(true);
        const config = { headers: { 'Content-type': 'application/json' }};
        const body = JSON.stringify(user);
        await axios.put(`/api/user/${cookies.LoginKey}`, body, config)
        .then((res) => {
            if(!res.data.ErrorMessage){
                setTimeout(() => {
                    let changeUser = { ...user };
                    changeUser.ChangePassword = '';
                    changeUser.ConfirmPassword = '';
                    setUser(changeUser);
                    setUpdating(false);
                    enqueueSnackbar(res.data.Message, { variant: 'success' });
                }, [1200]);
            }else{
                enqueueSnackbar(res.data.ErrorMessage, { variant: 'error' });
            }
        })
        .catch((err) => {
            console.log(err);
            enqueueSnackbar('Network Error!', { variant: 'error' });
        });
    };

    const handleClickUserDeleteBtn = async() => {
        setLoading(true);
        const config = { headers: { 'Content-type': 'application/json' }};
        let link = ''
        if(cookies.Status === '1'){
            // 상담소장 회원 탈퇴
            link = `/api/user/${cookies.LoginKey}`;
        }else{
            link = `/api/user/${user.UserNo}/${cookies.LoginKey}`;
        }
        await axios.delete(link, config)
        .then(async(res) => {
            if(!res.data.ErrorMessage){
                setTimeout(() => {
                    enqueueSnackbar(res.data.Message, { variant: 'info' });
                    logout();
                }, [2000]);
            }else{
                setLoading(false);
                enqueueSnackbar(res.data.ErrorMessage, { variant: 'error' });
            }
        })
        .catch((err) => {
            console.log(err);
            setLoading(false);
            enqueueSnackbar('Network Error!', { variant: 'error' });
        });
    };

    const logout = () => {
        removeCookies('LoginKey', { path: '/' });
        removeCookies('UserNo', { path: '/' });
        removeCookies('Name', { path: '/' });
        removeCookies('Status', { path: '/' });
        navigate(`/login`);
    };

    const loadData = useCallback(async() => {
        const config = { headers: { 'Content-type': 'application/json' }};
        await axios.get(`/api/user/${cookies.LoginKey}`, config)
        .then((res) => {
            if(!res.data.ErrorMessage){
                let loadUser = { ...res.data.UserData };
                loadUser.Available = loadUser.Available.length ? loadUser.Available : [[], [], [], [], [], [], []];
                loadUser.AvailableDay = [];
                for(let i = 0; i < loadUser.Available.length; i++){
                    if(loadUser.Available[i].length){
                        loadUser.AvailableDay.push((i+1).toString());
                        for(let j = 0; j < loadUser.Available[i].length; j++){
                            loadUser.Available[i][j] = loadUser.Available[i][j].toString();
                        }
                    }
                }
                for(let i = 0; i < loadUser.Region.length; i++){
                    loadUser.Region[i] = loadUser.Region[i].toString();
                }
                setUser(loadUser);
            }else{
                enqueueSnackbar(res.data.ErrorMessage, { variant: 'error' });
            }
        })
        .catch((err) => {
            console.log(err);
            enqueueSnackbar('Network Error!', { variant: 'error' });
        });
    }, []);

    useEffect(() => {
        loadData();
    }, [loadData]);

    return (
        <DashboardLayout pages={pages} status={props.status}>
            <UserUpdateView 
                user={user}
                setUser={setUser}
                handleClickUpdateBtn={handleClickUpdateBtn}
                handleClickUserDeleteBtn={handleClickUserDeleteBtn}
                updating={updating}
                loading={loading}
                status={cookies.Status}
            />
        </DashboardLayout>
    );
};

export default UserUpdateContainer;