import React, { useEffect, useState } from 'react';
import { ResponsiveLine } from '@nivo/line';
import { Grid, FormControl, FormGroup, FormControlLabel, Checkbox, Radio } from '@mui/material';

import { YyyyMmDd, YyyyMmDdPlusDay, YyMmDdDot } from 'components/Function/ChangeText';
import { DetailModal } from 'components/Items/Modal';
import { ItemLikert, ItemMultipleChoice, ItemShortForm } from 'components/Items/View';
import { TooltipButton } from 'components/Items/Button';
import ICONGREENPLUS from 'images/icon_green_plus.png';
import ICONRED from 'images/icon_red.png';
import ICONYELLOW from 'images/icon_yellow.png';
import ICONGREEN from 'images/icon_green.png';
import ICONORANGE from 'images/icon_orange.png';
import ICONLABEL from 'images/icon_label.png';

const ChartView = (props) => {
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    const [cnt, setCnt] = useState({ firstDay: '', schedule: 0, state2: 0, state3: 0, state4: 0 });
    const [scales, setScales] = useState([]);
    const [datas, setDatas] = useState([]);
    const [subScaleDatas, setSubScaleDatas] = useState([]);
    const [allChecked, setAllChecked] = useState(false);
    const [checkedScales, setCheckedScales] = useState([]);
    const [standard, setStandard] = useState(false);
    const [openScaleModal, setOpenScaleModal] = useState(false);
    const [selectScale, setSelectScale] = useState({});
    const [chartMin, setChartMin] = useState('auto');
    const [chartMax, setChartMax] = useState('auto');
    const [dateMin, setDateMin] = useState('auto');
    const [chartStandard, setChartStandard] = useState('0');
    const [markers, setMarkers] = useState([]);
    const [checkedSubScale, setCheckedSubScale] = useState(false);
    const [printWeeks, setPrintWeeks] = useState('every 7 days'); // 날짜 출력 주기
    // const [lastdate, setLastdate] = useState('');
    
    useEffect(() => {
      let loadScales = [];
      let loadDatas = [];
      let loadCheckedScales = [];
      let lastdate = '';
      const tumpSchedules = props.client.Schedule_ids.sort((a, b) => (new Date(a.StartDate) - new Date(b.StartDate)));
      const ScheduleCnt = tumpSchedules.length;
      const State2Cnt = tumpSchedules.filter((a) => (a.State === 2)).length;
      const State3Cnt = tumpSchedules.filter((a) => (a.State === 3)).length;
      const State4Cnt = tumpSchedules.filter((a) => (a.State === 4)).length;
      const tumpScales = props.client.Scales.filter((a) => (a.Scale_id.State === 1 && !a.Basic));
      for(let i = 0; i < tumpScales.length; i++){
        lastdate = '';
        if(tumpScales[i].Scale_id.CalScore){
          loadScales.push(tumpScales[i].Scale_id.Label);
          loadDatas.push({ id: tumpScales[i].Scale_id.Label, data: [] });
          loadCheckedScales.push(false);
          for(let j = 0; j < ( tumpSchedules.length > 10 ? tumpSchedules.length : 10); j++){
            if( j < tumpSchedules.length ) {
              if(tumpSchedules[j][`${tumpScales[i].Scale_id._id}`] !== undefined){
                loadDatas[loadDatas.length - 1].data.push({ 'x': YyyyMmDd(tumpSchedules[j].StartDate), 'rawy': tumpSchedules[j][`${tumpScales[i].Scale_id._id}`], ScheduleNo: tumpSchedules[j].ScheduleNo, Scale_id: tumpScales[i].Scale_id._id, 'max': tumpSchedules[j][`${tumpScales[i].Scale_id._id}Max`], 'scalemin': tumpScales[i].Scale_id.Min, 'scalemax': tumpScales[i].Scale_id.Max, 'scalestandard': tumpScales[i].Scale_id.Standard, 'negative': tumpScales[i].Scale_id.Negative, 'rci': tumpScales[i].Scale_id.RCI });
                lastdate = tumpSchedules[j].StartDate;
              } else {
                // console.log(j + '=' + lastdate);
                // loadDatas[loadDatas.length - 1].data.push({ 'x': YyyyMmDdPlusDay(lastdate, 1), 'rawy': null, ScheduleNo: null, Scale_id: null, 'max': null, 'scalemin': tumpScales[i].Scale_id.Min, 'scalemax': tumpScales[i].Scale_id.Max, 'scalestandard': tumpScales[i].Scale_id.Standard, 'negative': tumpScales[i].Scale_id.Negative });
                // resultCnt += 1;
                // if( lastdate === '' || tumpSchedules[j].StartDate > lastdate ) {
                //   lastdate = tumpSchedules[j].StartDate;
                // }
              }
            } else {
              if(tumpSchedules[0]){
                loadDatas[loadDatas.length - 1].data.push({ 'x': YyyyMmDdPlusDay(tumpSchedules[0].StartDate, j), 'rawy': null, ScheduleNo: null, Scale_id: null, 'max': null, 'scalemin': tumpScales[i].Scale_id.Min, 'scalemax': tumpScales[i].Scale_id.Max, 'scalestandard': tumpScales[i].Scale_id.Standard, 'negative': tumpScales[i].Scale_id.Negative, 'rci': tumpScales[i].Scale_id.RCI });
              }
            }
          }
        }
        if( lastdate !== '' ) {
          let k = loadDatas[loadDatas.length - 1].data.length;
          for(let i = loadDatas[loadDatas.length - 1].data.length; i < 10; i++){
            loadDatas[loadDatas.length-1].data.push({ 'x': YyyyMmDdPlusDay(lastdate, i - k + 1), 'rawy': null, ScheduleNo: null, Scale_id: null, 'max': null, 'scalemin': null, 'scalemax': null, 'scalestandard': null, 'negative': null, 'rci': null });
          }
        }
      }
    
      // let tumpGoals = [...props.goals];
      // loadScales.push('목표');
      // loadDatas.push({ id: '목표', data: [] });
      // loadCheckedScales.push(false);
      // for(let i = 0; i < tumpGoals.length; i++){
      //   for(let j = 0; j < tumpGoals[i].Points.length; j++){
      //     if(loadDatas[loadDatas.length - 1].data.filter((a) => (a.x === tumpGoals[i].Points[j].Date))[0]){
      //       const dateIdx = loadDatas[loadDatas.length - 1].data.indexOf(loadDatas[loadDatas.length - 1].data.filter((a) => (a.x === tumpGoals[i].Points[j].Date))[0]);
      //       loadDatas[loadDatas.length - 1].data[dateIdx].sum += Number(tumpGoals[i].Points[j].Point);
      //       loadDatas[loadDatas.length - 1].data[dateIdx].cnt += 1;
      //       loadDatas[loadDatas.length - 1].data[dateIdx].rawy = loadDatas[loadDatas.length - 1].data[dateIdx].sum/loadDatas[loadDatas.length - 1].data[dateIdx].cnt;
      //     }else{
      //       loadDatas[loadDatas.length - 1].data.push({ 'x': tumpGoals[i].Points[j].Date, 'rawy': tumpGoals[i].Points[j].Point, 'sum': tumpGoals[i].Points[j].Point, 'cnt': 1, 'max': 100, 'scalemin': '0', 'scalemax': '100', 'scalestandard': 0, 'negative': 0 });
      //     }
      //   }
      //   for(let j = loadDatas[loadDatas.length - 1].data.length; j < 10; j++){
      //     loadDatas[loadDatas.length - 1].data.push({ 'x': YyyyMmDdPlusDay(tumpGoals[0].Points[0].Date, j), 'rawy': null, 'max': null, 'scalemin': '0', 'scalemax': '100', 'scalestandard': 0, 'negative': 0 });
      //   }
      // }
      // for(let i = 0; i < loadDatas.length; i++){
      //   loadDatas[i].data = loadDatas[i].data.sort((a, b) => (new Date(a.x) - new Date(b.x)));
      // }
      // 날짜 출력 주기 수정
      let minDate = '2000-01-01';
      let maxDate = '2000-01-01';
      let concatData = [];
      for(let i = 0; i < loadDatas.length; i++){
        concatData = concatData.concat(loadDatas[i].data);
      }
      concatData.sort((a, b) => (new Date(a.x) - new Date(b.x)));
      if(concatData.length){
        minDate = concatData[0].x;
        maxDate = concatData[concatData.length-1].x;
      }
      const diffWeek = (new Date(maxDate)-new Date(minDate))/(1000 * 60 * 60 * 24 * 7); // 날짜 차이 / (밀리초 * 초 * 분 * 시간 * 일)
      if(diffWeek <= 12){
        setPrintWeeks('every 7 days');
      }else if(diffWeek <= 24){
        setPrintWeeks('every 14 days');
      }else if(diffWeek <= 48){
        setPrintWeeks('every 28 days');
      }else if(diffWeek <= 96){
        setPrintWeeks('every 56 days');
      }else{
        setPrintWeeks('every 84 days');
      }
      setScales(loadScales);
      setDatas(loadDatas);
      setCheckedScales(loadCheckedScales);
      // x축 최소값 
      // 날짜 구하기
      if(loadDatas.length){
        let changeMinDate = 'auto';
        for(let i = 0; i < loadDatas.length; i++){
          if(loadDatas[i].data[0]?.x){
            if(changeMinDate === 'auto'){
              changeMinDate = loadDatas[i].data[0].x;
            }else if(new Date(changeMinDate) > new Date(loadDatas[i].data[0].x)){
              changeMinDate = loadDatas[i].data[0].x;
            }
          }
        }
        setCnt({ firstDay: changeMinDate, schedule: ScheduleCnt, state2: State2Cnt, state3: State3Cnt, state4: State4Cnt });
        // -1 일
        const tumpDate = changeMinDate !== 'auto' ? YyyyMmDd(new Date(new Date(changeMinDate).setDate(new Date(changeMinDate).getDate() -1))) : 'auto';
        setDateMin(tumpDate);
      }
      setAllChecked(false);
    }, [props]);
    
    const handleChange = (e) => {
        if(Number(e.target.value) !== 0){
            let changeCheckedScales = [...checkedScales];
            changeCheckedScales[Number(e.target.value) - 1] = !checkedScales[Number(e.target.value) - 1];
            setCheckedScales(changeCheckedScales);
        }else{
          let changeChecked = [...checkedScales];
          for(let i = 0; i < changeChecked.length; i++){
            changeChecked[i] = !allChecked;
          }
          setCheckedScales(changeChecked);
          setAllChecked(!allChecked);
        }
    };
    const filterDatas = (datas) => {
        return datas.filter((item) => {
            let returnData = false;
            for(let i = 0; i < checkedScales.length; i++){
                if(checkedScales[i] && item.id === scales[i]){
                    returnData = true;
                }
            }
            return returnData;
        });
    };
    const reverseData = (datas) => {
      let returnData = [];
      for(let i = datas.length-1; i >= 0; i--){
        returnData.push(datas[i]);
      }
      return returnData;
    };

    const dataPoint = (data, option) => {
      let first, end, sum, sum2, rciper, icon, cutoff, rci;
      for(let i = 0; i < data.length; i++){
        if(data[i].rawy){
          if(first){
            end = data[i].rawy;
          }else{
            first = data[i].rawy;
            rci = data[i].rci
            cutoff = data[i].scalestandard;
          }
        }
      }
      
      if(end && first){
        sum = (((end - first)/(Math.abs(first)))*100).toFixed(1);
        sum = ((end - first)/(Math.abs(first))) > 0 ? `+${sum}%` : `${sum}%`;
        sum2 = end - first > 0 ? '+'+(end - first).toFixed(1).replace('.0', '') : (end - first).toFixed(1).replace('.0', '');
        if(rci){
          rciper = (((end - first)/rci)*100).toFixed(1);
          if(rciper >= 100){
            if(rci >= 0 && end >= cutoff){
              icon = ICONGREENPLUS; // 회복된 사례  
            }else if(rci < 0 && end <= cutoff){
              icon = ICONGREENPLUS; // 회복된 사례  
            }else{
              icon = ICONGREEN; // 개선된 사례
            }
          }else if(rciper <= -100){
            icon = ICONRED; // 악화된 사례
          }else{
            if(rciper >= 0){
              icon = ICONYELLOW; // 살짝 좋아지고 있음
            }else{
              icon = ICONORANGE; // 살짝 나빠지고 있음
            }
          }
          rciper = (end - first)/rci > 0 ? `+${rciper}%` : `${rciper}%`;
        }
      }
      if(option === 1){
        return first;
      }else if(option === 2){
        return end;
      }else if(option === 3){
        return sum;
      }else if(option === 4){
        return sum2;
      }else if(option === 5){
        return rciper;
      }else if(option === 6){
        return icon;
      }else{ 
        return '-';
      }
    };

    const handleClickPoint = (node) => {
      // if(isMobile){
      //   return false;
      // }else{
        if(node.data?.ScheduleNo && !checkedSubScale){
          const tumpSchedule = props.client.Schedule_ids.filter((item) => (item.ScheduleNo === Number(node.data.ScheduleNo)))[0];
          if(tumpSchedule){
            const tumpAnswer = tumpSchedule.ScaleAnswer.filter((item) => (item.Scale_id === node.data.Scale_id))[0];
            const scaleAnswer = { Answer: tumpAnswer.Answer, Scale_id: props.client.Scales.filter((item) => (item.Scale_id._id === node.data.Scale_id))[0].Scale_id }
            setSelectScale(scaleAnswer);
            setOpenScaleModal(true);
          }else{ return false; }
        }else{
          return false;
        }
      // }
    };


    useEffect(() => {
      setTimeout(() => {
        let changeDatas = [...datas];
        let max = 50;
        if(!checkedSubScale){
          for(let i = 0; i < checkedScales.length; i++){
            if(checkedScales[i]){
              if(checkedScales.filter((item) => (item === true)).length === 1){
                for(let j = 0; j < changeDatas[i].data.length; j++){
                  changeDatas[i].data[j].y = changeDatas[i].data[j].rawy;
                  if( j === 0 ) {
                    setChartMin(changeDatas[i].data[j].scalemin);
                    setChartMax(changeDatas[i].data[j].scalemax);
                    max = changeDatas[i].data[j].scalemax;
                    setChartStandard((400-(((changeDatas[i].data[j].scalestandard)/changeDatas[i].data[j].scalemax)*100*4)));
                  }
                }
              }else{
                for(let j = 0; j < changeDatas[i].data.length; j++){
                  if( changeDatas[i].data[j].negative ) {
                    changeDatas[i].data[j].y = changeDatas[i].data[j].max ? changeDatas[i].data[j].rawy/changeDatas[i].data[j].scalemax*100 : (changeDatas[i].data[j].max === null ? null : 0 );
                    if( changeDatas[i].data[j].y !== null ) {
                      changeDatas[i].data[j].y = 100 - changeDatas[i].data[j].y;
                    }
                  } else {
                    changeDatas[i].data[j].y = changeDatas[i].data[j].max ? changeDatas[i].data[j].rawy/changeDatas[i].data[j].scalemax*100 : (changeDatas[i].data[j].max === null ? null : 0 );
                  }
                }
                setChartMin('0');
                setChartMax('100');
                max = 100;
                setChartStandard(400);
              }
            }
          }
          if(checkedScales.filter((item) => (item === true)).length === 0){
            setChartStandard(400);
          }
          setDatas(changeDatas);
        }

        // y축 평행선 cutoff
        let loadMarkers = [];
        for(let i = 0; i < datas.length; i++){  
          if(standard && checkedScales[i] && datas[i].data[0]?.scalestandard){
            // y축 환산 cutoff
            let ratioCutoff = datas[i].data[0]?.scalemax ? datas[i].data[0]?.scalestandard * (max/datas[i].data[0].scalemax) : datas[i].data[0]?.scalestandard;
            loadMarkers.push({
              axis: 'y',
              legend: `${datas[i].id} Cutoff (${datas[i].data[0]?.scalestandard})`,
              legendPosition: 'bottom-left',
              lineStyle: {
                stroke: '#b0413e',
                strokeWidth: 2,
                stroke: 'rgba(255, 0, 0, 0.5)',
                strokeDasharray: '3 6'
              },
              textStyle: {
                fontSize: '12px'
              },
              value: ratioCutoff,
            });
          }
        }
        setMarkers(loadMarkers);
      }, [200]);
      // 하위요인 체크
      let changeDatas = [];
      const tumpSchedules = props.client.Schedule_ids.sort((a, b) => (new Date(a.StartDate) - new Date(b.StartDate)));
      const tumpScales = props.client.Scales.filter((a) => (a.Scale_id.State === 1 && !a.Basic));
      if(checkedSubScale){ // 하위요인 체크 여부
        let tumpMax = 0;
        let tumpMin = 0;
        if(checkedScales.filter((a) => (a === true)).length === 1){
          for(let i = 0; i < tumpScales.length; i++){
            if(checkedScales[i]){
              tumpMax = tumpScales[i].Scale_id.SubMax ? tumpScales[i].Scale_id.SubMax : 100;
              tumpMin = tumpScales[i].Scale_id.SubMin ? tumpScales[i].Scale_id.SubMin : 0;
            }
          }
        }else{
          tumpMax = 100;
          tumpMin = 0;
        }
        setChartMin(tumpMin);
        setChartMax(tumpMax);
        for(let i = 0; i < tumpScales.length; i++){ //  검사 개수 만큼 반복
          if(checkedScales[i]){ // 검사 체크 여부
            let subScaleName = '';
            for(let j = 0; j < tumpScales[i].Scale_id.SubScale.length; j++){ // 하위 요인 개수 만큼 반복
              let tumpData = [];
              subScaleName = `${tumpScales[i].Scale_id.Label} ${tumpScales[i].Scale_id.SubScale[j]}`;
              for(let k = 0; k < tumpSchedules.length; k++){ 
                
                  let sumPoint = 0;
                  let tumpItems = tumpScales[i].Scale_id.Item_ids.filter((a) => (a.SubScale === j+1)); // 하위 요인 일치하는 문항들
                  let insert = false;
                  for(let q = 0; q < tumpItems.length; q++){ // 하위 요인의 문항 개수 만큼 반복 
                    let tumpAnswers = tumpSchedules[k].ScaleAnswer.filter((a) => (a.Scale_id === tumpScales[i].Scale_id._id))[0]; // 1회기 상담에서 답변 모두
                    let tumpAnswer = tumpAnswers?.Answer.filter((a) => (a.Item_id === tumpItems[q]._id))[0]?.Answer; // 일치하는 문항의 답변
                    insert = tumpAnswer ? true : false;
                    for(let s = 0; s < tumpAnswer?.length; s++){ // 답변 개수 만큼 반복
                      const tumpPoint = tumpItems[q].Points[tumpAnswer[s]];
                      sumPoint += tumpPoint;
                    }
                  }
                  if(insert){
                    if(checkedScales.filter((a) => (a === true)).length === 1 || !tumpScales[i].Scale_id.SubMax){
                      tumpData.push({ x: YyyyMmDd(tumpSchedules[k].StartDate), y: sumPoint, rawy: sumPoint });  
                    }else{
                      tumpData.push({ x: YyyyMmDd(tumpSchedules[k].StartDate), y: sumPoint*(100/tumpScales[i].Scale_id.SubMax), rawy: sumPoint });  
                    }
                  }
              }
              if(tumpData.length < 10 && tumpData.length > 0){
                const lastDate = tumpData[tumpData.length-1].x;
                const r = 11 - tumpData.length;
                for(let k = 1; k < r; k++){
                  tumpData.push({ x: YyyyMmDdPlusDay(lastDate, k), y: null, rawy: null });
                }
              }
              changeDatas.push({ id: subScaleName, data: tumpData });  
            }
          }  
        }
      }
      setSubScaleDatas(changeDatas);      
    }, [checkedScales, standard, checkedSubScale]);
  
    return (
        <>
        <Grid container>
          <Grid item xs={12} sm={12} md={2.5} style={{ padding: '16px', border: '1px solid #EAECF0', borderRadius: '15px'}}>
                <h3 style={{ textAlign: 'center', marginTop: '0px' }}>차트 관리</h3>
                <p style={{ textAlign: 'center', marginTop: '0px' }}>검사 선택</p>
                <FormControl fullWidth>
                    <FormGroup onChange={handleChange}>
                        <FormControlLabel control={<Checkbox value={'0'} checked={allChecked} />} label={'ALL'} disabled={scales.length === 0}/>
                        {scales.map((scales, i) => (
                        <FormControlLabel key={i} control={<Checkbox value={i+1} checked={checkedScales[i]} />} label={scales} />
                        ))}
                    </FormGroup>
                </FormControl>
                <p style={{ textAlign: 'center', marginTop: '0px' }}>옵션</p>
                <FormControl fullWidth>
                    <FormGroup>
                      {/* <FormControlLabel control={<Checkbox />} label={'배경색'} onChange={() => setBgc(!bgc)}/> */}
                      <FormControlLabel control={<Radio checked={checkedSubScale} onClick={() => {setStandard(false);setCheckedSubScale(!checkedSubScale);}} />} label={
                        <Grid container alignItems={'center'}>
                          <Grid item>
                            하위요인
                          </Grid>
                          <Grid item>
                            <TooltipButton tooltip={'검사의 하위요인(문항)별 그래프를 제시해 줍니다.'} size='medium' />
                          </Grid>
                        </Grid>
                      }/>
                      <FormControlLabel control={<Radio checked={standard} onClick={() => {setStandard(!standard);setCheckedSubScale(false);}} />} label={
                        <Grid container alignItems={'center'}>
                          <Grid item>
                            Cutoff
                          </Grid>
                          <Grid item>
                            <TooltipButton tooltip={'임상군과 비임상군을 구분하는데 기준이 되는 점수로, 특정 검사의 점수를 기준으로 치료의 필요 여부와 회복 정도를 평가할 때 참고할 수 있습니다.'} size='medium'/>
                          </Grid>
                        </Grid>
                      } />
                    </FormGroup>
                </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={9.5}>
                <Grid height={'500px'} marginBottom={'16px'} style={{ position: 'relative' }}>
                {/* <div style={{ background: 'linear-gradient(blue, yellow, red)', opacity: 0.5, height: '500px', width: '100%', position: 'absolute', display: bgc ? 'block' : 'none' }} /> */}
                  <ResponsiveLine
                    xFormat='time:%Y-%m-%d'
                    xScale={{
                      format: '%Y-%m-%d',
                      precision: 'day',
                      type: 'time',
                      min: dateMin
                    }}
                    axisBottom={{
                      format: '%y.%m.%d',
                      tickValues: printWeeks,
                      tickSize: 5,
                        tickPadding: 5,
                        tickRotation: 0,
                        legendOffset: 36,
                        legendPosition: 'middle',
                        legend: '날짜',
                    }}
                    data={reverseData(checkedSubScale ? subScaleDatas : filterDatas(datas))}
                    // data={checkedSubScale ? subScaleDatas : filterDatas(datas)}
                    margin={{ top: 50, right: 110, bottom: 50, left: 60 }}
                    yScale={{
                        type: 'linear',
                        min: chartMin,
                        max: chartMax,
                        reverse: false
                    }}
                    // layers={[
                    //   'grid',
                    //      ({ innerWidth }) => {      
                    //     if( chartStandard === 400 ) return null;
                    //     return (
                    //         <line
                    //             x1={0}
                    //             y1={chartStandard}
                    //             x2={innerWidth}
                    //             y2={chartStandard}
                    //             stroke="rgba(255, 0, 0, 0.5)"
                    //             strokeDasharray='3 6'
                    //             strokeWidth={2}
                    //         />
                    //     );
                    // },
                    //   'markers',
                    //   'areas',
                    //   'lines',
                    //   'slices',
                    //   'axes',
                    //   'points',
                    //   'mesh',
                    //   'legends',
                    // ]}
                    yFormat=' >-.2f'
                    axisTop={null}
                    axisRight={null}
                    axisLeft={{
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: 0,
                        legend: '점수',
                        legendOffset: -40,
                        legendPosition: 'middle'
                    }}
                    pointSize={10}
                    // colors={{ scheme: 'category10' }}
                    pointColor={{ theme: 'background' }}
                    pointBorderWidth={2}
                    pointBorderColor={{ from: 'serieColor' }}
                    pointLabelYOffset={-12}
                    useMesh={true}
                    legends={[
                        { 
                            anchor: 'top-right',
                            direction: 'column',
                            justify: false,
                            translateX: 100,
                            translateY: 0,
                            itemsSpacing: 0,
                            itemDirection: 'left-to-right',
                            itemWidth: 80,
                            itemHeight: 20,
                            itemOpacity: 0.75,
                            symbolSize: 12,
                            symbolShape: 'circle',
                            symbolBorderColor: 'rgba(0, 0, 0, .5)',
                            effects: [
                                {
                                    on: 'hover',
                                    style: {
                                        itemBackground: 'rgba(0, 0, 0, .03)',
                                        itemOpacity: 1
                                    }
                                }
                            ]
                        }
                    ]}
                    tooltip={(e) => (
                      <Grid container sx={{ boxShadow: 1, p: 1, bgcolor: '#FFF', borderRadius: '4px', fontSize: '14px' }} alignItems={'center'}>
                          <Grid item sx={{ pr: 0.5 }}><div style={{ width: '15px', height: '15px', backgroundColor: e.point.serieColor, borderRadius: '7.5px' }}/></Grid>
                          <Grid item sx={{ pr: 1 }}>{e.point.serieId}</Grid>
                          <Grid item sx={{ pr: 1 }}>
                              <span style={{ color: '#C0C0C0'}}>{YyyyMmDd(e.point.data.x)}: </span> {e.point.data.rawy}
                          </Grid>
                      </Grid>
                    )}
                    enableSlices={checkedSubScale ? 'x' : false}
                    enableTouchCrosshair
                    sliceTooltip={(e) => (
                      <Grid container sx={{ boxShadow: 1, p: 1, bgcolor: '#FFF', borderRadius: '4px', fontSize: '14px' }} alignItems={'center'}>
                        <table>
                          <tr>
                            <th colSpan={3} style={{ textAlign: 'left', padding: '2px' }}>{e.slice.points[0].data.xFormatted}</th>
                          </tr>
                        {e.slice.points.map((item, key) => (
                          <tr key={key}>
                            <th style={{ padding: '2px' }}><div style={{ width: '15px', height: '15px', backgroundColor: item.serieColor, borderRadius: '7.5px' }}/></th>
                            <td>{item.serieId}</td>
                            <td>: {item.data.rawy}</td>
                          </tr>
                        ))}
                        </table>
                      </Grid>
                    )}
                    onClick={(node, _) => handleClickPoint(node)}
                    markers={markers}
                    // theme={{ 'background': bgc ? 'rgba(255, 255, 0, 0.1)' : 'rgba(0, 0, 0, 0)' }}
                />
                </Grid>
                <Grid container alignItems={'stretch'} style={{ padding: '16px 8px 0px 8px'}}>
                    <Grid item xs={12} sm={12} md={4} lg={3}>
                        <Grid style={{ padding: '0px 8px', height: '100%' }} >
                            <Grid style={{ padding: '16px', border: '1px solid #EAECF0', borderRadius: '15px', height: '100%' }}>
                                <h3 style={{ textAlign: 'center', marginTop: '0px' }}>일정 요약</h3>
                                <Grid container justifyContent={'space-between'}>
                                    <Grid item sx={{ whiteSpace: 'nowrap' }}>시작</Grid>
                                    <Grid item>{YyMmDdDot(cnt.firstDay)}</Grid>
                                </Grid>
                                <Grid container justifyContent={'space-between'}>
                                    <Grid item xs={7} sm={7} md={7} lg={6} sx={{ whiteSpace: 'nowrap' }}>약속 일정</Grid>
                                    <Grid item>{cnt.schedule}</Grid>
                                </Grid>
                                <Grid container justifyContent={'space-between'} style={{ fontSize: '80%', paddingLeft: '5%' }}>
                                    <Grid item xs={7} sm={7} md={7} lg={6}>- 참석</Grid>
                                    <Grid item>{cnt.state4}</Grid>
                                </Grid>
                                <Grid container justifyContent={'space-between'} style={{ fontSize: '80%', paddingLeft: '5%' }}>
                                    <Grid item xs={7} sm={7} md={7} lg={6}>- 취소</Grid>
                                    <Grid item>{cnt.state2}</Grid>
                                </Grid>
                                <Grid container justifyContent={'space-between'} style={{ fontSize: '80%', paddingLeft: '5%' }}>
                                    <Grid item xs={7} sm={7} md={7} lg={6}>- 불참</Grid>
                                    <Grid item>{cnt.state3}</Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12} md={8} lg={9}>
                        <Grid style={{ padding: '0px 8px', height: '100%' }} >
                            <Grid style={{ padding: '16px', border: '1px solid #EAECF0', borderRadius: '15px', height: '100%' }}>
                                <h3 >
                                <Grid container justifyContent={'center'} alignItems={'center'}>
                                  <Grid item>
                                  검사 결과 평균
                                  </Grid>
                                  <Grid item sx={{ ml: 1 }}>
                                    <TooltipButton tooltip={`- 각 검사의 초기(첫번째) 검사 결과와 가장 마지막에 시행한 검사 결과를 바탕으로 계산합니다.
                                    - 증감: 마지막 결과 – 초기 결과
                                    - 증감률:  [(마지막 결과 - 초기 결과) / 초기 결과] * 100
                                    - RCI % : (증감 / RCI) * 100 (해당 검사에 Reliable Change Index (RCI)가 있는 경우 계산됨)  
                                    `} />
                                  </Grid>
                                </Grid>
                                </h3>
                                <table className='mini_table'>
                                    <thead>
                                        <tr>
                                            <th>검사</th>
                                            <th>초기</th>
                                            <th>마지막</th>
                                            <th>증감</th>
                                            <th>증감률</th>
                                            <th>RCI%</th>
                                            <th style={{ position: 'relative' }}><img src={ICONLABEL} style={{ position: 'absolute', width: '24px', height: '24px', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }} alt='icon_label'/></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {filterDatas(datas).length > 0 ?
                                        filterDatas(datas).map((data, i) => (
                                        <tr key={i}>
                                          <td>{data.id}</td>
                                          <td>{dataPoint(data.data, 1)}</td>
                                          <td>{dataPoint(data.data, 2)}</td>
                                          <td>{dataPoint(data.data, 4)}</td>
                                          <td>{dataPoint(data.data, 3)}</td>
                                          <td>{dataPoint(data.data, 5)}</td>
                                          <td style={{ padding: 0 }}>
                                            {dataPoint(data.data, 6) ?
                                            <img src={dataPoint(data.data, 6)} alt='icon' style={{ width: '24px', marginTop: '4px' }} />:
                                            null}
                                          </td>
                                        </tr>
                                        )):
                                        <tr>
                                          <td colSpan={6}></td>
                                          <td></td>
                                        </tr>}
                                    </tbody>
                                </table>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
        {selectScale.Scale_id ?
        <DetailModal
          title={selectScale.Scale_id.Label}
          open={openScaleModal}
          handleClose={() => {setOpenScaleModal(false);setSelectScale({});}}
        >
        <Grid item xs={12}>
            <Grid style={{ marginBottom : '16px' }}>
                {selectScale.Scale_id.Detail}
            </Grid>
            {selectScale.Scale_id.Item_ids.sort((a, b) => (a.SubScale - b.SubScale)).map((q, j) => (
            <Grid key={j} style={{ marginBottom: '8px', padding: '4px 8px' }}>
                {q.ItemKind === 1 ?
                <ItemShortForm
                    item={q} 
                    no={j+1} 
                    scale={selectScale.Scale_id} 
                    subScale={q.SubScale && (j === 0 || q.SubScale !== selectScale.Scale_id.Item_ids[j-1].SubScale)} 
                    lastItem={selectScale.Scale_id.Item_ids.length === j+1}
                    answer={selectScale.Answer.filter((a) => (a.Item_id === q._id))[0]?.Answer}
                /> :
                q.ItemKind === 2 ?
                <ItemMultipleChoice
                    item={q}
                    no={j+1}
                    scale={selectScale.Scale_id}
                    subScale={q.SubScale && (j === 0 || q.SubScale !== selectScale.Scale_id.Item_ids[j-1].SubScale)}
                    lastItem={selectScale.Scale_id.Item_ids.length === j+1}
                    answer={selectScale.Answer.filter((a) => (a.Item_id === q._id))[0]?.Answer}
                /> :
                q.ItemKind === 3 ?
                <ItemLikert
                    item={q}
                    no={j+1}
                    scale={selectScale.Scale_id}
                    subScale={q.SubScale && (j === 0 || q.SubScale !== selectScale.Scale_id.Item_ids[j-1].SubScale)}
                    lastItem={selectScale.Scale_id.Item_ids.length === j+1}
                    answer={selectScale.Answer.filter((a) => (a.Item_id === q._id))[0]?.Answer}
                    float={true}
                /> :
                null}
            </Grid>
            ))}
        </Grid>
        </DetailModal>:null}
        </>
    );
};

export default ChartView;