import React, { useEffect, useState } from 'react';
import { Form, useNavigate, useParams } from 'react-router-dom';
import { Grid, FormControl, FormGroup, FormControlLabel, Checkbox, FormHelperText, Radio, RadioGroup } from '@mui/material';

import { DetailContainer } from 'components/Items/Container';
import { DataBox, DataTable, DataTableButton } from 'components/Items/Table';
import { ConfirmModal, FormModal, DetailModal } from 'components/Items/Modal';
import { DateForm, TimeForm, SelectForm, NumberForm, TextForm, SearchForm } from 'components/Items/Form';
import { SessionKind, Service, RepeatDay, PossibleDay, PossibleTime, RepeatCount, SesstionState } from 'components/Function/AddDatas';
import { GenderKo, NationalityKo, MarriageKo, MilitaryKo, EmploymentKo, LivingKo, YyyyMmDd, ScheduleKindKo, ServiceKo } from 'components/Function/ChangeText';
import { MedicationKo, ReferralKo, DesiredServiceKo, ReasonKo, DesiredTestKo, RiskKo, HowKo } from 'components/Function/ChangeText';

const ClientDetailView = (props) => {
    const session = props.session;
    const setSession = props.setSession;
    const selectSession = props.selectSession;
    const setSelectSession = props.setSelectSession;
    const rooms = props.rooms;
    const setRooms = props.setRooms;
    const navigate = useNavigate();
    const { clientNo } = useParams();
    const datas = [
        { key: '이름', value: props.client.Name },
        { key: '내담자ID', value: props.client.NickName },
        { key: '이메일', value: props.client.Email },
        { key: '연락처', value: props.client.Phone },
        { key: '비고', value: props.client.Memo, long: true },
        { key: '현 주소', value: props.client.Address },
        { key: '생년월일', value: props.client.Birth },
        { key: '성별', value: props.client.Gender !== 3 ? GenderKo(props.client.Gender) : props.client.GenderDetail },
        { key: '국적', value: props.client.Nationality !== 9 ? NationalityKo(props.client.Nationality) : props.client.NationalityDetail },
        { key: '종교', value: props.client.Religion },
        { key: '결혼유무', value: props.client.Marriage !== 6 ? MarriageKo(props.client.Marriage) : props.client.MarriageDetail },
        { key: '병역', value: MilitaryKo(props.client.Military) },
        { key: '최종학력', value: props.client.Education },
        { key: '직업', value: props.client.Employment !== 9 || !props.client.EmploymentDetail ? EmploymentKo(props.client.Employment) : props.client.EmploymentDetail },
        { key: '주거 형태', value: props.client.Living !== 6 ? LivingKo(props.client.Living) :props.client.LivingDetail },
        { key: '부양가족 수', value: props.client.Dependents || props.client.Dependents === 0 ? `${props.client.Dependents} 명` : '' },,
        { key: '그룹', value: props.client.Group ? props.client.Group.Name : '' },
        { key: '상담자', value: props.client.Counselor_id ? props.client.Counselor_id.Name : '' },
        { key: '신청일', value: props.client.CreatedAtValue }
    ];
    const keys = [{ key: 'No', label: 'No.'},
                        { key: 'CounselorName', label: '상담자'},
                        { key: 'StartDateValue', label: '상담 일시'},
                        { key: 'KindDetailValue', label: '상담 종류'},
                        { key: 'ServiceValue', label: '상담 방식' },
                        { key: 'StateValue', label: '방문 여부' }];

    const [search, setSearch] = useState('');
    const [disabledCreateBtn, setDisabledCreateBtn] = useState(true);
    const [openClientDeleteModal, setOpenClientDeleteModal] = useState(false);
    const [openSessionDetailModal, setOpenSessionDetailModal] = useState(false);
    const [openSessionCreateModal, setOpenSessionCreateModal] = useState(false);
    const [openSessionUpdateModal, setOpenSessionUpdateModal] = useState(false);
    const [openSessionDeleteModal, setOpenSessionDeleteModal] = useState(false);
    const [openUrlSendModal, setOpenUrlSendModal] = useState(false);
    const [scaleNo, setScaleNo] = useState('');

    const handleCloseUrlSend = () => {
        setOpenUrlSendModal(false);
        props.setSend({ Email: false, Kakao: false });
    };
    
    const handleCloseCreateSession = () => {
        setOpenSessionCreateModal(false);
        setSession({
            Client_id: props.client._id,
            Counselor_id: props.client.Counselor_id ? props.client.Counselor_id._id : '',
            StartDate: '',
            StartTime: '',
            EndTime: '',
            Service: '',
            State: '',
            Status: 1,
            Room_id: '',
            Kind: { State: 1, Detail: '' },
            Repeat: { State: false, Count: '', Days: '' },
            IsFee: false,
            Fee: '',
            Memo: ''
        });
    };
    const handleChangeKindDetail = (e) => {
        let changeSession = { ...session };
        changeSession.Kind.Detail = e.target.value;
        setSession(changeSession);
    };
    const handleChangeStartTime = (e) => {
        let changeSession = { ...session };
        changeSession.StartTime = e.target.value;
        let tumpTime = new Date(`1980-01-01 ${e.target.value}`);
        tumpTime = new Date(tumpTime.setMinutes(tumpTime.getMinutes() + 50));
        const tumpHours = tumpTime.getHours() > 9 ? tumpTime.getHours() : `0${tumpTime.getHours()}`;
        const tumpMinutes = tumpTime.getMinutes() > 9 ? tumpTime.getMinutes() : `0${tumpTime.getMinutes()}`;
        changeSession.EndTime = `${tumpHours}:${tumpMinutes}`
        setSession(changeSession)
    };
    const handleChangeRepeatCount = (e) => {
        let changeSession = { ...session };
        changeSession.Repeat.Count = e.target.value;
        setSession(changeSession);
    };
    const handleChangeRepeatDays = (e) => {
        let changeSession = { ...session };
        changeSession.Repeat.Days = e.target.value;
        setSession(changeSession);
    };

    useEffect(() => {
        let tump = false;
        if(!session.Kind.Detail){
            tump = true;
        }
        if(!session.StartDate){
            tump = true;
        }
        if(!session.StartTime){
            tump = true;
        }
        if(!session.Service){
            tump = true;
        }
        if(!session.Counselor_id){
            tump = true;
        }
        setDisabledCreateBtn(tump);
    }, [session]);

    const handleResetRooms = () => {
        let changeRooms = [...rooms];
        for(let i = 0; i < changeRooms.length; i++){
            changeRooms[i].disabled = false;
        }
        if(session.StartDate && session.StartTime){
            for(let i = 0; i < changeRooms.length; i++){
                let tumpDatas = changeRooms[i].Schedule_ids.filter((item) => (new Date(item.StartDate) - new Date(session.StartDate) === 0));
                tumpDatas = tumpDatas.filter((item) => ((
                    new Date(`2000-01-01 ${session.StartTime}`) >= new Date(`2000-01-01 ${item.StartTime}`) && 
                    new Date(`2000-01-01 ${session.StartTime}`) <= new Date(`2000-01-01 ${item.EndTime}`)
                ) || (
                    new Date(`2000-01-01 ${session.EndTime}`) >= new Date(`2000-01-01 ${item.StartTime}`) && 
                    new Date(`2000-01-01 ${session.EndTime}`) <= new Date(`2000-01-01 ${item.EndTime}`)
                )));
                if(tumpDatas.length > 0){
                    changeRooms[i].disabled = true;
                }
            }
        }
        if(selectSession.StartDate && selectSession.StartTime){
            for(let i = 0; i < changeRooms.length; i++){
                let tumpDatas = changeRooms[i].Schedule_ids.filter((item) => (new Date(item.StartDate) - new Date(selectSession.StartDate) === 0 && selectSession._id !== item._id));
                tumpDatas = tumpDatas.filter((item) => ((
                    new Date(`2000-01-01 ${selectSession.StartTime}`) >= new Date(`2000-01-01 ${item.StartTime}`) && 
                    new Date(`2000-01-01 ${selectSession.StartTime}`) <= new Date(`2000-01-01 ${item.EndTime}`)
                ) || (
                    new Date(`2000-01-01 ${selectSession.EndTime}`) >= new Date(`2000-01-01 ${item.StartTime}`) && 
                    new Date(`2000-01-01 ${selectSession.EndTime}`) <= new Date(`2000-01-01 ${item.EndTime}`)
                )));
                if(tumpDatas.length > 0){
                    changeRooms[i].disabled = true;
                }
            }
        }
        setRooms(changeRooms);
    };
    
    useEffect(() => {
        handleResetRooms();
    }, [session.StartDate, session.StartTime, selectSession.StartDate, selectSession.StartTime]);

    const handleClickClientDelete = () => {
        setOpenClientDeleteModal(true);
    };

    const handleClickSessionDetail = (data) => {
        setSelectSession(data);
        setOpenSessionDetailModal(true);
    };

    const handleClickSessionUpdate = (data) => {
        setSelectSession(data);
        setOpenSessionUpdateModal(true);
    };

    const handleChangeKindDetailUpdate = (e) => {
        let changeSession = { ...selectSession };
        changeSession.Kind.Detail = e.target.value;
        setSelectSession(changeSession);
    };
    const handleChangeStartTimeUpdate = (e) => {
        let changeSession = { ...selectSession };
        changeSession.StartTime = e.target.value;
        let tumpTime = new Date(`1980-01-01 ${e.target.value}`);
        tumpTime = new Date(tumpTime.setMinutes(tumpTime.getMinutes() + 50));
        const tumpHours = tumpTime.getHours() > 9 ? tumpTime.getHours() : `0${tumpTime.getHours()}`;
        const tumpMinutes = tumpTime.getMinutes() > 9 ? tumpTime.getMinutes() : `0${tumpTime.getMinutes()}`;
        changeSession.EndTime = `${tumpHours}:${tumpMinutes}`
        setSelectSession(changeSession)
    };
    const handleChangeRepeatCountUpdate = (e) => {
        let changeSession = { ...selectSession };
        changeSession.Repeat.Count = e.target.value;
        setSelectSession(changeSession);
    };
    const handleChangeRepeatDaysUpdate = (e) => {
        let changeSession = { ...selectSession };
        changeSession.Repeat.Days = e.target.value;
        setSelectSession(changeSession);
    };

    const handleClickSessionDelete = (data) => {
        setSelectSession(data);
        setOpenSessionDeleteModal(true);
    };

    return (
        <>
            <DetailContainer
                title='내담자 정보'
                icon={[ { type: 'delete', handleClick: handleClickClientDelete },
                        { type: 'update', handleClick: () => navigate(`/manage/client/${clientNo}/update`) } ]}
            >
                <DataBox
                    datas={datas}
                />
                <Grid container style={{ marginTop: '8px' }}>
                    <Grid item xs={12}>
                        <Grid
                            container
                            style={{
                                borderRadius: '5px',
                                border: '1px solid #C0C0C0'
                            }}
                            alignItems={'stretch'}
                        >
                            <Grid
                                item 
                                xs={4} sm={3} md={1.5} 
                                style={{ 
                                    backgroundColor: '#D9D9D9', 
                                    textAlign: 'center', 
                                    fontWeight: 'bold', 
                                    borderRadius: '5px 0px 0px 5px', 
                                    padding: '4px 16px', 
                                    overflow: 'hidden', 
                                    whiteSpace: 'nowrap', 
                                    textOverflow: 'ellipsis'
                                }}
                            >
                                희망 상담시간
                            </Grid>
                            <Grid 
                                item
                                xs={8} sm={9} md={10.5}
                                style={{
                                    overflow: 'hidden',
                                    whiteSpace: 'nowrap',
                                    textOverflow: 'ellipsis'
                                }}
                            >
                                <Grid container style={{ textAlign: 'center', borderBottom: '1px solid #C0C0C0', fontSize: '80%' }}>
                                    <Grid item xs={12/8} style={{ borderRight: '1px solid #C0C0C0' }}>
                                    </Grid>
                                    {PossibleDay().map((day, i) => (
                                    <Grid item xs={12/8} key={i} style={{ borderRight: i < 6 ? '1px solid #C0C0C0' : '0px' }}>
                                        {day.label.replace('요일', '')}
                                    </Grid>
                                    ))}
                                </Grid>
                                {PossibleTime().map((time, i) => (
                                <Grid container key={i} style={{ textAlign: 'center', borderBottom: i < 17 ? '1px solid #C0C0C0' : '0px', fontSize: '80%' }}>
                                    <Grid item xs={12/8} style={{ borderRight: '1px solid #C0C0C0' }}>
                                        {time.label.split('~')[0].replace(':00 ', '시')}
                                    </Grid>
                                    {PossibleDay().map((day, j) => (
                                    <Grid
                                        item
                                        xs={12/8}
                                        key={j}
                                        style={{ 
                                            backgroundColor: 
                                                props.client.AvailableDay.indexOf(day.value) > -1 && props.client.Available[Number(day.value)-1].indexOf(time.value) > -1 ?
                                                'rgba(161, 216, 160, 0.7)' : 
                                                '', 
                                            borderRight: j < 6 ? '1px solid #C0C0C0' : '0px'
                                        }}
                                    />
                                    ))}
                                </Grid>
                                ))}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container style={{ marginBottom: '16px', marginTop: '16px' }}>
                    <Grid item xs={12}>
                        <h3>신청 정보</h3>
                    </Grid>
                    <Grid item xs={12} className='table_normal'>
                        <table>
                            <thead>
                                <tr>
                                    <th>복용약물</th><th colSpan={2}>약물 명</th><th>위험도</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>{MedicationKo(props.client.Medication)}</td><td colSpan={2}>{props.client.MedicationDetail}</td><td>{RiskKo(props.client.Risk)}</td>
                                </tr>
                            </tbody>
                            <thead>
                                <tr>
                                    <th colSpan={2}>찾아오게된 경위</th><th colSpan={2}>찾아온 목적</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td colSpan={2}>{props.client.Referral === 4 ? props.client.ReferralDetail : ReferralKo(props.client.Referral)}</td>
                                    <td colSpan={2}>{props.client.DesiredService.length > 0 ? props.client.DesiredService.map((item, i) => (<span key={i}>{i === 0 ? '' : ', '}{DesiredServiceKo(item)}</span>)) : 'ㅤ'}</td>
                                </tr>
                            </tbody>
                            <thead>
                                <tr>
                                    <th colSpan={2}>상담 받고 싶은 점</th><th colSpan={2}>받고 싶은 검사</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td colSpan={2}>{props.client.Reason.length > 0 ? props.client.Reason.map((item, i) => (<span key={i}>{i === 0 ? '' : ', '}{ReasonKo(item)}</span>)) : 'ㅤ'}</td>
                                    <td colSpan={2}>{props.client.DesiredTest.length > 0 ? props.client.DesiredTest.map((item, i) => (<span key={i}>{i === 0 ? '' : ', '}{DesiredTestKo(item)}</span>)) : 'ㅤ'}</td>
                                </tr>
                            </tbody>
                            <thead>
                                <tr>
                                    <th colSpan={4}>현재 고민/어려움 (혹은 상담목표)</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td colSpan={4} style={{ whiteSpace: 'normal' }}>{props.client.Concern ? <div dangerouslySetInnerHTML={{ __html: props.client.Concern.replaceAll('\n', '<br/>') }} /> : 'ㅤ'}</td>
                                </tr>
                            </tbody>
                            <thead>
                                <tr>
                                    <th>이전 상담 여부</th><th>언제</th><th>어디서</th><th>치료유형</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>{props.client.PriorTreatment.PriorExperience ? '있음' : '없음'}</td>
                                    <td>{props.client.PriorTreatment.When}</td>
                                    <td>{props.client.PriorTreatment.Where}</td>
                                    <td>{props.client.PriorTreatment.How.length > 0 ? props.client.PriorTreatment.How.map((item, i) => (<span key={i}>{i === 0 ? '' : ', '}{HowKo(item)}</span>)) : 'ㅤ'}</td>
                                </tr>
                            </tbody>
                        </table>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <h3>가족관계 (또는 긴급 연락처)</h3>
                </Grid>
                <Grid item xs={12} className='table_normal'>
                    <table>
                        <thead>
                            <tr>
                                <th>관계</th><th>연령</th><th>이름</th><th>연락처</th>
                            </tr>
                        </thead>
                        <tbody>
                            {props.client.Family[0]?.Name && props.client.Family[0]?.Relation && props.client.Family[0]?.Age && props.client.Family[0]?.Phone ?
                            props.client.Family.map((item, i) => (
                            <tr key={i}>
                                <td style={{ borderRadius: i === props.client.Family.length - 1 ? '0px 0px 0px 8px' : '0px' }}>{item.Relation}</td><td>{item.Age}</td><td>{item.Name}</td><td>{item.Phone}</td>
                            </tr>
                            )):
                            <tr>
                                <td style={{ borderRadius: '0px 0px 8px 8px' }} colSpan={4}>긴급 연락처가 없습니다.</td>
                            </tr>}
                        </tbody>
                    </table>
                </Grid>
            </DetailContainer>
            <DetailContainer
                title='내담자 상담 목록'
                button={{ label: '상담 생성', handleClick: () => setOpenSessionCreateModal(true) }}
            >
                <Grid container>
                    <Grid item xs={12} md={6} style={{ marginBottom: '16px'}}>
                        <SearchForm
                            value={search}
                            handleChange={(e) => setSearch(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <DataTableButton
                            keys={keys}
                            datas={props.sessions}
                            search={search}
                            update={true}
                            delete={true}
                            handleClickDetail={handleClickSessionDetail}
                            handleClickUpdate={handleClickSessionUpdate}
                            handleClickDelete={handleClickSessionDelete}
                            defaultSort={[0, 1]}
                            client={props.client}
                            setOpenUrlSendModal={setOpenUrlSendModal}
                            setSelectSession={setSelectSession}
                            setScaleNo={setScaleNo}
                            // manage={true}
                        />
                        {/* <DataTable
                            keys={keys}
                            datas={props.sessions}
                            delete={true}
                            update={true}
                            search={search}
                            handleClickDetail={handleClickSessionDetail}
                            handleClickUpdate={handleClickSessionUpdate}
                            handleClickDelete={handleClickSessionDelete}
                        /> */}
                    </Grid>
                </Grid>
            </DetailContainer>
            {/* 내담자 삭제 모달 */}
            <ConfirmModal
                title='내담자 삭제'
                icon='error'
                open={openClientDeleteModal}
                handleClose={() => setOpenClientDeleteModal(false)}
                submitLabel='삭제'
                handleClickSubmit={() => props.handleClickDeleteUserBtn()}
            >
                내담자 정보를 삭제 하시겠습니까? 내담자 삭제 시 복구가 불가능합니다.
            </ConfirmModal>
            {/* 내담자 상담 상세 모달 */}
            <DetailModal
                title='내담자 상담 상세 보기'
                open={openSessionDetailModal}
                handleClose={() => setOpenSessionDetailModal(false)}
            >
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <p>상담 종류</p>
                        <div className='div_data_value'>{ScheduleKindKo(selectSession.Kind.Detail)}</div>
                    </Grid>
                    <Grid item xs={12}>
                        <p>상담일</p>
                        <div className='div_data_value'>{YyyyMmDd(selectSession.StartDate)}</div>
                    </Grid>
                    <Grid item xs={6}>
                        <p>상담 시작 시간</p>
                        <div className='div_data_value'>{selectSession.StartTime}</div>
                    </Grid>
                    <Grid item xs={6}>
                        <p>상담 종료 시간</p>
                        <div className='div_data_value'>{selectSession.EndTime}</div>
                    </Grid>
                    <Grid item xs={12}>
                        <p>상담 방식</p>
                        <div className='div_data_value'>{ServiceKo(selectSession.Service)}</div>
                    </Grid>
                    <Grid item xs={6}>
                        <p>상담자</p>
                        <div className='div_data_value'>{selectSession.Counselor_id.Name}</div>
                    </Grid>
                    <Grid item xs={6}>
                        <p>상담실</p>
                        <div className='div_data_value'>{selectSession.Room_id ? selectSession.Room_id.Name : '-'}</div>
                    </Grid>
                    <Grid item xs={12}>
                        <p>메모</p>
                        <div className='div_data_value' dangerouslySetInnerHTML={{ __html: selectSession.Memo ? selectSession.Memo.replaceAll('\n', '<br/>') : 'ㅤ' }}/>
                    </Grid>
                    {selectSession.IsFee ?
                    <Grid item xs={12}>
                        <p>상담비</p>
                        <div className='div_data_value'>{selectSession.Fee?.toLocaleString()} 원</div>
                    </Grid>:null}
                    {selectSession.Repeat.State ?
                    <Grid item xs={12}>
                        <p>반복</p>
                        <div className='div_data_value'>{selectSession.Repeat.Days} 일 / {selectSession.Repeat.Count} 회</div>
                    </Grid>:null}
                </Grid>
            </DetailModal>
            {/* 내담자 상담 생성 모달 */}
            <FormModal
                title='내담자 상담 생성'
                open={openSessionCreateModal}
                handleClose={handleCloseCreateSession}
                submitLabel='생성'
                handleClickSubmit={() => { props.handleClickCreateBtn();setOpenSessionCreateModal(false); }}
                disabled={disabledCreateBtn}
            >
                <SelectForm
                    label='상담 종류'
                    required={true}
                    error={false}
                    value={session.Kind.Detail}
                    datas={SessionKind()}
                    handleChange={handleChangeKindDetail}
                />
                <DateForm
                    label='상담 일'
                    required={true}
                    error={false}
                    value={session.StartDate}
                    handleChange={(e) => setSession({ ...session, StartDate: e.target.value })}
                />
                <Grid container spacing={1}>
                    <Grid item xs={6}>
                        <TimeForm
                            label='상담 시작 시간'
                            required={true}
                            error={false}
                            value={session.StartTime}
                            handleChange={handleChangeStartTime}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TimeForm
                            label='상담 종료 시간'
                            required={true}
                            error={false}
                            value={session.EndTime}
                            handleChange={(e) => setSession({ ...session, EndTime: e.target.value })}
                        />
                    </Grid>
                </Grid>
                <SelectForm
                    label='서비스 방식'
                    required={true}
                    error={false}
                    value={session.Service}
                    datas={Service()}
                    handleChange={(e) => setSession({ ...session, Service: e.target.value })}
                />
                <Grid container spacing={1}>
                    <Grid item xs={6}>
                        <SelectForm
                            label='상담자'
                            required={true}
                            error={false}
                            disabled={false}
                            value={props.session.Counselor_id}
                            datas={props.members}
                            handleChange={(e) => setSession({ ...session, Counselor_id: e.target.value })}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <SelectForm
                            label='상담실'
                            required={false}
                            error={!Boolean(session.StartDate) || !Boolean(session.StartTime)}
                            errorMessage={'상담 일 및 시간을 먼저 설정해주세요.'}
                            cancel={true}
                            disabled={!Boolean(session.StartTime)}
                            value={session.Room_id}
                            datas={props.rooms}
                            handleChange={(e) => setSession({ ...session, Room_id: e.target.value })}
                        />
                    </Grid>
                </Grid>
                <TextForm
                    label='메모'
                    required={false}
                    error={false}
                    value={session.Memo}
                    rows={4}
                    handleChange={(e) => setSession({ ...session, Memo: e.target.value })}
                />
                <Grid container spacing={1}>
                    <Grid item xs={4}>
                    <FormControl fullWidth>
                        <Grid style={{ marginBottom: '8px' }}>
                            상담비 지불 여부
                        </Grid>
                        <RadioGroup row>
                            <FormControlLabel value={true} onChange={() => setSession({ ...session, IsFee: true })} control={<Radio checked={Boolean(session.IsFee)} />} label={'O'} />
                            <FormControlLabel value={false} onChange={() => setSession({ ...session, IsFee: false })} control={<Radio checked={!Boolean(session.IsFee)} />} label={'X'} />
                        </RadioGroup>
                        <FormHelperText style={{ marginLeft: 0 }}> </FormHelperText>
                    </FormControl>
                    </Grid>
                    <Grid item xs={8}>
                        <NumberForm
                            label='상담비'
                            required={false}
                            error={false}
                            // disabled={!Boolean(session.IsFee)}
                            placeholder='상담비를 입력해 주세요.'
                            value={session.Fee}
                            handleChange={(e) => setSession({ ...session, Fee: e.target.value })}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={1} alignItems={'stretch'}>
                    <Grid item xs={4}>
                        <FormControl fullWidth>
                            <Grid style={{ marginBottom: '8px' }}>
                                반복 여부
                            </Grid>
                            <FormGroup row>
                                <FormControlLabel 
                                    onChange={() => setSession({ ...session, Repeat: { State: !Boolean(session.Repeat.State), Count: '', Days: '' }})} 
                                    control={<Checkbox checked={Boolean(session.Repeat.State)}/>} 
                                />
                            </FormGroup>
                            <FormHelperText style={{ marginLeft: 0, marginTop: 16, whiteSpace: 'nowrap' }}>* 반복 설정 시 상담실은 첫번째 상담만 설정됩니다.</FormHelperText>
                        </FormControl>
                    </Grid>
                    <Grid item xs={4}>
                        <SelectForm
                            label='반복 횟수'
                            required={false}
                            error={false}
                            disabled={!Boolean(session.Repeat.State)}
                            value={session.Repeat.Count}
                            datas={RepeatCount()}
                            handleChange={handleChangeRepeatCount}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <SelectForm
                            label='반복 주기'
                            required={false}
                            error={false}
                            disabled={!Boolean(session.Repeat.State)}
                            value={session.Repeat.Days}
                            datas={RepeatDay()}
                            handleChange={handleChangeRepeatDays}
                        />
                    </Grid>
                </Grid>
            </FormModal>

            {/* 내담자 상담 수정 모달 */}
            <FormModal
                title='내담자 상담 수정'
                open={openSessionUpdateModal}
                handleClose={() => setOpenSessionUpdateModal(false)}
                submitLabel='수정'
                handleClickSubmit={() => { props.handleClickUpdateBtn(); setOpenSessionUpdateModal(false); }}
            >
                <SelectForm
                    label='상담 종류'
                    required={true}
                    error={false}
                    value={selectSession.Kind.Detail}
                    datas={SessionKind()}
                    handleChange={handleChangeKindDetailUpdate}
                />
                <DateForm
                    label='상담 일'
                    required={true}
                    error={false}
                    value={YyyyMmDd(selectSession.StartDate)}
                    handleChange={(e) => setSelectSession({ ...selectSession, StartDate: e.target.value })}
                />
                <Grid container spacing={1}>
                    <Grid item xs={6}>
                        <TimeForm
                            label='상담 시작 시간'
                            required={true}
                            error={false}
                            value={selectSession.StartTime}
                            handleChange={handleChangeStartTimeUpdate}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TimeForm
                            label='상담 종료 시간'
                            required={true}
                            error={false}
                            value={selectSession.EndTime}
                            handleChange={(e) => setSelectSession({ ...selectSession, EndTime: e.target.value })}
                        />
                    </Grid>
                </Grid>
                <SelectForm
                    label='서비스 방식'
                    required={true}
                    error={false}
                    value={selectSession.Service}
                    datas={Service()}
                    handleChange={(e) => setSelectSession({ ...selectSession, Service: e.target.value })}
                />
                <Grid container spacing={1}>
                    <Grid item xs={6}>
                        <SelectForm
                            label='상담자'
                            required={true}
                            error={false}
                            disabled={false}
                            value={selectSession.Counselor_id._id}
                            datas={props.members}
                            handleChange={(e) => setSelectSession({ ...selectSession, Counselor_id: { _id: e.target.value }})}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <SelectForm
                            label='상담실'
                            required={false}
                            error={!Boolean(selectSession.StartDate) || !Boolean(selectSession.StartTime)}
                            errorMessage={'상담 일 및 시간을 먼저 설정해주세요.'}
                            cancel={true}
                            disabled={!Boolean(selectSession.StartTime)}
                            value={selectSession.Room_id ? selectSession.Room_id._id : ''}
                            datas={props.rooms}
                            handleChange={(e) => setSelectSession({ ...selectSession, Room_id: { _id: e.target.value }})}
                        />
                    </Grid>
                </Grid>
                <TextForm
                    label='메모'
                    required={false}
                    error={false}
                    value={selectSession.Memo}
                    rows={4}
                    handleChange={(e) => setSelectSession({ ...selectSession, Memo: e.target.value })}
                />
                <Grid container spacing={1}>
                    <Grid item xs={12} style={{ marginBottom: '8px' }}>
                        <FormControl fullWidth>
                            <Grid style={{ marginBottom: '8px' }}>
                                방문 여부
                            </Grid>
                            <RadioGroup row>
                                {SesstionState().map((item, i) => (
                                    <FormControlLabel 
                                        key={i} 
                                        value={item.value}
                                        onChange={(e) => setSelectSession({ ...selectSession, State: Number(e.target.value), Room_id: Number(e.target.value) === 2 || Number(e.target.value) === 3 ? '' : selectSession.Room_id })}
                                        control={<Radio
                                            checked={Number(selectSession.State) === Number(item.value)}
                                        />}
                                        label={item.label}
                                        disabled={false}
                                    />
                                ))}
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid container spacing={1}>
                    <Grid item xs={4}>
                        <FormControl fullWidth>
                            <Grid style={{ marginBottom: '8px' }}>
                                상담비 지불 여부
                            </Grid>
                            <RadioGroup row>
                                <FormControlLabel value={true} onChange={() => setSelectSession({ ...selectSession, IsFee: true })} control={<Radio checked={Boolean(selectSession.IsFee)} />} label={'O'} />
                                <FormControlLabel value={false} onChange={() => setSelectSession({ ...selectSession, IsFee: false })} control={<Radio checked={!Boolean(selectSession.IsFee)} />} label={'X'} />
                            </RadioGroup>
                            <FormHelperText style={{ marginLeft: 0 }}> </FormHelperText>
                        </FormControl>
                    </Grid>
                    <Grid item xs={8}>
                        <NumberForm
                            label='상담비'
                            required={false}
                            error={false}
                            // disabled={!Boolean(selectSession.IsFee)}
                            placeholder='상담비를 입력해 주세요.'
                            value={selectSession.Fee}
                            handleChange={(e) => setSelectSession({ ...selectSession, Fee: e.target.value })}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={1}>
                    <Grid item xs={4}>
                        <FormControl fullWidth>
                            <Grid style={{ marginBottom: '8px' }}>
                                반복 여부
                            </Grid>
                            <FormGroup row>
                                <FormControlLabel 
                                    onChange={() => setSelectSession({ ...selectSession, Repeat: { State: !Boolean(selectSession.Repeat.State), Count: '', Days: '' }})} 
                                    control={<Checkbox checked={Boolean(selectSession.Repeat.State)}/>} 
                                />
                            </FormGroup>
                            <FormHelperText style={{ marginLeft: 0 }}> </FormHelperText>
                        </FormControl>
                    </Grid>
                    <Grid item xs={4}>
                        <SelectForm
                            label='반복 횟수'
                            required={false}
                            error={false}
                            disabled={!Boolean(selectSession.Repeat.State)}
                            value={selectSession.Repeat.Count}
                            datas={RepeatCount()}
                            handleChange={handleChangeRepeatCountUpdate}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <SelectForm
                            label='반복 주기'
                            required={false}
                            error={false}
                            disabled={!Boolean(selectSession.Repeat.State)}
                            value={selectSession.Repeat.Days}
                            datas={RepeatDay()}
                            handleChange={handleChangeRepeatDaysUpdate}
                        />
                    </Grid>
                </Grid>
            </FormModal>
            <ConfirmModal
                title='내담자 일정 삭제'
                icon='error'
                open={openSessionDeleteModal}
                handleClose={() => setOpenSessionDeleteModal(false)}
                handleClickSubmit={() => { props.handleClickDeleteBtn();setOpenSessionDeleteModal(false); }}
                submitLabel='삭제'
            >
                내담자 상담 일정을 삭제 하시겠습니까? 내담자 상담 삭제 시 복구가 불가능합니다.
            </ConfirmModal>
            <FormModal
                title='URL 전송'
                open={openUrlSendModal}
                handleClose={() => handleCloseUrlSend()}
                submitLabel='확인'
                handleClickSubmit={() => { props.handleClickUrlSendBtn(selectSession.ScheduleNo, scaleNo);handleCloseUrlSend(); }}
                disabled={false}
            >
                <FormControl fullWidth>
                    <FormGroup>
                        <FormControlLabel 
                            value={props.send.Email}
                            onChange={() => props.setSend({ ...props.send, Email: !props.send.Email })}
                            control={<Checkbox checked={props.send.Email}/>}
                            label={`이메일 전송 (${props.client.Email_decrypt})`}
                        />
                        <FormControlLabel 
                            value={props.send.Kakao}
                            onChange={() => props.setSend({ ...props.send, Kakao: !props.send.Kakao })}
                            disabled={true}
                            // disabled={!Boolean(props.client.Phone_decrypt) || !Boolean(props.client.Phone_decrypt.indexOf('010') > -1)}
                            control={<Checkbox checked={props.send.Kakao}/>}
                            label={`카카오톡 전송 ${props.client.Phone_decrypt ? `(${props.client.Phone_decrypt})` : ''}`}
                        />
                    </FormGroup>
                    <FormHelperText style={{ marginLeft: 0 }}> </FormHelperText>
                </FormControl>
            </FormModal>
        </>
    );
};

export default ClientDetailView;