import React, { useState } from 'react';
import { Grid, IconButton, Collapse, Switch, FormControlLabel } from '@mui/material';
import { KeyboardArrowUp, DeleteOutlineOutlined, CreateOutlined } from '@mui/icons-material';
import { SmallCancelButton } from '../Button';

const DetailContainer = (props) => {
    const [turn, setTurn] = useState(props.collapse || 0);
    const handleClick = () => {
        if(props.changeHeight){
            props.changeHeight();
        }
        if(turn === 180){
            setTurn(0);
        }else{
            setTurn(180);
        }
    };

    return (
        <Grid container justifyContent={'center'}>
            <Grid item xs={12} style={{ backgroundColor: '#FFF', borderRadius: '20px', marginBottom: '16px', overflow: 'hidden' }} sx={{ boxShadow: props.shadow === 'none' ? 0 : 1 }}>
                {props.h4 ?
                <h4 style={{ backgroundColor: props.shadow === 'none' ? '#FFF' : '#C6E7CF', margin: '0', borderRadius: '20px 20px 0 0', padding: props.shadow === 'none' ? '16px 8px' : '16px 24px' }}>
                    <Grid container justifyContent={'space-between'}>
                        <Grid item>
                            {props.title}
                            <IconButton onClick={handleClick}>
                                <KeyboardArrowUp style={{ transform: `rotate(${turn}deg)`, transition: 'all ease 0.25s' }} />
                            </IconButton>
                        </Grid>
                        <Grid item>
                            {props.switch ?
                            <FormControlLabel control={<Switch disabled={props.switch.disabled} color='default' checked={props.switch.value} onChange={props.switch.handleChange} />} label={props.switch.label} />
                            :null}
                            {props.icon ?
                                props.icon.map((item, i) => (
                                <IconButton key={i} onClick={item.handleClick} style={{ marginRight: '8px' }}>
                                    {item.type === 'update' ?
                                    <CreateOutlined/>:
                                    <DeleteOutlineOutlined/>}
                                </IconButton>
                                )):
                            null}
                            {props.button ?
                            <SmallCancelButton
                                label={props.button.label}
                                handleClick={props.button.handleClick}
                            />
                            :null}
                        </Grid>
                    </Grid>
                </h4>:
                props.h3 ?
                <h3 style={{ backgroundColor: props.shadow === 'none' ? '#FFF' : '#C6E7CF', margin: '0', borderRadius: '20px 20px 0 0', padding: props.shadow === 'none' ? '16px 8px' : '16px 24px' }}>
                    <Grid container justifyContent={'space-between'}>
                        <Grid item>
                            {props.title}
                            <IconButton onClick={handleClick}>
                                <KeyboardArrowUp style={{ transform: `rotate(${turn}deg)`, transition: 'all ease 0.25s' }} />
                            </IconButton>
                        </Grid>
                        <Grid item>
                            {props.switch ?
                            <FormControlLabel control={<Switch disabled={props.switch.disabled} color='default' checked={props.switch.value} onChange={props.switch.handleChange} />} label={props.switch.label} />
                            :null}
                            {props.icon ?
                                props.icon.map((item, i) => (
                                <IconButton key={i} onClick={item.handleClick} style={{ marginRight: '8px' }}>
                                    {item.type === 'update' ?
                                    <CreateOutlined/>:
                                    <DeleteOutlineOutlined/>}
                                </IconButton>
                                )):
                            null}
                            {props.button ?
                            <SmallCancelButton
                                label={props.button.label}
                                handleClick={props.button.handleClick}
                            />
                            :null}
                        </Grid>
                    </Grid>
                </h3>
                :
                <h2 style={{ backgroundColor: props.shadow === 'none' ? '#FFF' : '#C6E7CF', margin: '0', borderRadius: '20px 20px 0 0', padding: props.shadow === 'none' ? '16px 8px' : '16px 24px' }}>
                    <Grid container justifyContent={'space-between'}>
                        <Grid item>
                            {props.title}
                            <IconButton onClick={handleClick}>
                                <KeyboardArrowUp style={{ transform: `rotate(${turn}deg)`, transition: 'all ease 0.25s' }} />
                            </IconButton>
                        </Grid>
                        <Grid item>
                            {props.switch ?
                            <FormControlLabel control={<Switch disabled={props.switch.disabled} color='default' checked={props.switch.value} onChange={props.switch.handleChange} />} label={props.switch.label} />
                            :null}
                            {props.icon ?
                                props.icon.map((item, i) => (
                                <IconButton key={i} onClick={item.handleClick} style={{ marginRight: '8px' }}>
                                    {item.type === 'update' ?
                                    <CreateOutlined/>:
                                    <DeleteOutlineOutlined/>}
                                </IconButton>
                                )):
                            null}
                            {props.button ?
                            <SmallCancelButton
                                label={props.button.label}
                                handleClick={props.button.handleClick}
                            />
                            :null}
                        </Grid>
                    </Grid>
                </h2>}
                {!Boolean(turn) ?
                <Grid style={{ padding: props.shadow === 'none' ? '16px 8px' : '16px 24px' }}>
                    <Collapse in={!Boolean(turn)}>
                        {props.children}
                    </Collapse>
                </Grid>:null}
            </Grid>
        </Grid>
    );
};

export default DetailContainer;