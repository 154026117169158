import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Grid } from '@mui/material';

import { ListContainer } from 'components/Items/Container';
import { SearchForm } from 'components/Items/Form';
import { SmallSubmitButton } from 'components/Items/Button';
import { DataTable } from 'components/Items/Table';
import { ConfirmModal } from 'components/Items/Modal';

const MemberListView = (props) => {
    const navigate = useNavigate();
    const { selectMember, setSelectMember, handleClickDeleteBtn } = props;
    const [search, setSearch] = useState('');
    const keys = [
        { key: 'No', label: 'No.' },
        { key: 'Name', label: '이름' },
        { key: 'ID', label: 'ID' },
        { key: 'Email', label: '이메일' },
        { key: 'Phone', label: '연락처' },
        { key: 'StatusKo', label: '권한' },
    ];
    
    const [openMemberDeleteModal, setOpenMemberDeleteModal] = useState(false);
    const filterDatas = (datas) => {
        return datas;
    };
    const handleClickDetail = (data) => {
        navigate(`/manage/member/${data.UserNo}`);
    };
    const handleClickUpdate = (data) => {
        navigate(`/manage/member/${data.UserNo}/update`);
    };
    const handleClickDelete = (data) => {
        setSelectMember(data);
        setOpenMemberDeleteModal(true);
    };
    return (
        <>
        <ListContainer title='직원 목록'>
            <Grid container justifyContent={'space-between'}>
                <Grid item sm={12} md={5} style={{ marginBottom: '16px' }}>
                    <SearchForm
                        value={search}
                        handleChange={(e) => setSearch(e.target.value)}
                    />
                </Grid>
                <Grid container item sm={12} md={7} justifyContent={'flex-end'} alignItems={'center'} style={{ marginBottom: '16px' }}>
                    <Grid item xs={6} textAlign={'right'}>
                        <SmallSubmitButton 
                            label='직원 생성'
                            handleClick={() => navigate('/manage/member/create')}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid>
                <DataTable
                    keys={keys}
                    datas={filterDatas(props.members)}
                    delete={true}
                    update={true}
                    search={search}
                    handleClickDetail={handleClickDetail}
                    handleClickUpdate={handleClickUpdate}
                    handleClickDelete={handleClickDelete}
                />
            </Grid>
        </ListContainer>
        {selectMember.UserNo ? 
        <ConfirmModal
            title='직원 삭제'
            icon='error'
            open={openMemberDeleteModal}
            handleClose={() => setOpenMemberDeleteModal(false)}
            submitLabel='삭제'
            handleClickSubmit={() => {setOpenMemberDeleteModal(false);handleClickDeleteBtn();}} 
        >
            직원 정보를 삭제 하시겠습니까? 직원 삭제 시 복구가 불가능합니다.
        </ConfirmModal>:null}
        </>
    );
};

export default MemberListView;